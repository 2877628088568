import React from "react";
import { Helmet } from "react-helmet";

// import components
import { DashboardRoutesWeights } from "../../../components";

// import css styles
import "../../../index.css";

const Home = () => {
	return (
		<>
			<Helmet>
				<title>لوحة التحكم | الرئيسية</title>
			</Helmet>

			<div className='app_container'>
				{/* DashboardRoutesWeights */}
				<div className='flex  w-full h-full  justify-center items-center '>
					<DashboardRoutesWeights />
				</div>
			</div>
		</>
	);
};

export default Home;
