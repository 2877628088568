import React, { useContext } from "react";
import { Helmet } from "react-helmet";

// components
import { PagesBreadcrumbs } from "../../../components/index";

import CustomerServiceInfoModal from "./customerServiceInfo/CustomerServiceInfo";
import CustomerServiceTable from "./customerServiceTable/CustomerServiceTable";
import Context from "../../../context/Context";

const CustomerService = () => {
	// this context to open and close customer service info modal
	const customerServiceModalContext = useContext(Context);
	const { openCustomerServiceModal } = customerServiceModalContext;

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | خدمة العملاء </title>
			</Helmet>

			<div className='py-4 px-14 w-[90%]'>
				{/* Pages Bread crumbs */}
				<section>
					<PagesBreadcrumbs
						currentPage={"خدمة العملاء"}
						parentPage={null}
						isNested={false}
					/>
				</section>
				{/* Customer Service Table component */}
				<section className='mb-6'>
					<CustomerServiceTable />
				</section>
				{/* Customer Service Info Modal */}
				{openCustomerServiceModal && <CustomerServiceInfoModal />}
			</div>
		</>
	);
};

export default CustomerService;
