import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let DeletePageSectionThunk = createAsyncThunk(
  "section/DeletePageSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let res = await axios.delete(
        `/api/admin/pages/${arg?.id}/sections/${arg?.section_id}`
      );

      return res.data;
    } catch (error) {
      // console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
