import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	DeleteIcon,
	ImagesIcon,
	PreviewIcon,
	TextIcon,
	UploadImagesIcon,
} from "../../../assets/icons";
import {
	PagesBreadcrumbs,
	PreviewVideoModal,
	SectionLabel,
} from "../../../components";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";

// css styles

import { Close, SaveAs } from "@mui/icons-material";
import { LinkIcon, PlusIcon } from "evergreen-ui";
import { useRef } from "react";
import {
	setCurrentSectionDataStateRow,
	setSectionDataState,
} from "../../../RTK/Reducers/HomePageReducer";
import { AddMainSectionInHomeThunk } from "../../../RTK/Thunk/AddMainSectionInHomeThunk";
import { AllMainSectionDataThunk } from "../../../RTK/Thunk/AllMainSectionDataThunk";
import { OneMainPagesSectionThunk } from "../../../RTK/Thunk/OneMainPagesSectionThunk";
import TextEditorWithForm from "../../../components/textEditor/TextEditorWithForm";
import { Loader } from "../../../components/loader";

const schema = Yup.object().shape({
	text: Yup.string().required("قم بإضافة المحتوى النصي"),
	ingredients: Yup.array()
		// test select options and radio buttons
		.of(
			Yup.object().shape({
				title: Yup.string().required("قم بإضافة اسم القسم"),
				link: Yup.mixed().optional("قم بإضافة ربط القسم"),
				file: Yup.mixed().required("قم بإضافة صورة القسم"),
				previewImgOrVideo: Yup.mixed().optional("قم بإضافة اسم القسم"),
			})
		),
});
const HomeWhoAreWeSection = ({ showModalAddSection }) => {
	let { name } = useParams();
	let dispatch = useDispatch();
	let Navigate = useNavigate();
	let { section_type, reload, currentSectionData } = useSelector(
		(state) => state.HomePageReducer
	);
	// =======react-form====================
	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		resetField,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			text: "",
			ingredients: [
				{
					title: "",

					file: null,
					previewImgOrVideo: null,
					link: "",
				},
			],
		},
	});

	const {
		fields: ingredientsFields,
		append: appendIngredientField,
		remove: removeIngredientField,
	} = useFieldArray({
		control,
		name: "ingredients",
	});

	const fileInputRef = React.createRef();
	const handleDeleteImages = (blockIndex) => {
		const updatedBlocks = [...watch("ingredients")];
		updatedBlocks[blockIndex].file = null;
		updatedBlocks[blockIndex].previewImgOrVideo = null;
		setValue("ingredients", updatedBlocks);
		fileInputRef.current.value = null;
	};
	// handle openPreview image modal
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
	const [videoSrc, setVideoSrc] = useState(false);
	const handleOpenPreviewVideoModal = (previewImgOrVideo) => {
		setOpenPreviewModal(true);
		setVideoSrc(previewImgOrVideo);
	};
	// Function to handle file input change
	const handleSetImageInput = (blockIndex, event) => {
		const file = event?.target?.files[0];
		const imgConvert = URL.createObjectURL(file);

		const updatedBlocks = [...watch("ingredients")];
		updatedBlocks[blockIndex].file = file;

		updatedBlocks[blockIndex].previewImgOrVideo = imgConvert;

		setValue("ingredients", updatedBlocks);
	};
	// Function to open the file input dialog
	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	// set err text default
	useEffect(() => {
		// errors?.page_icon;
		if (watch("text") !== "") {
			setError("text");
		}
	}, [setError, watch("text")]);
	// ==================================

	const [pageTarget, setPageTarget] = useState(1);
	const [targetEdit, setTargetEdit] = useState({ type: true, id: "1" });

	// get current data target updated
	const getCurrentDataTargetRef = useRef(true);
	useEffect(() => {
		if (getCurrentDataTargetRef.current) {
			getCurrentDataTargetRef.current = false;
			dispatch(
				OneMainPagesSectionThunk({
					type: "who_are_we",
				})
			);
		}
	}, [getCurrentDataTargetRef]);
	const currentSectionDataRef = React.useRef(true);
	useEffect(() => {
		if (currentSectionData !== null && currentSectionDataRef.current) {
			// console.log(currentSectionData)
			// set ingredients arr data
			removeIngredientField(0);
			appendIngredientField({
				link: currentSectionData?.link || "",
				file: currentSectionData?.file,
				previewImgOrVideo: currentSectionData?.file,
				title: name,
			});
			setValue("text", currentSectionData?.text);

			currentSectionDataRef.current = false;
		}
	}, [currentSectionData]);
	// ==================
	let setFunctionWithEdit = () => {
		setValue("text", "");
		setValue("text", "");
		setTargetEdit({ type: false, id: "" });
		window.scroll(0, 0);
		removeIngredientField(0);
		appendIngredientField({
			title: "",
			file: null,
			previewImgOrVideo: null,
			link: "",
		});
		dispatch(setCurrentSectionDataStateRow());
		Navigate("/website-home-page");
	};
	const onSubmit = async (data) => {
		dispatch(
			AddMainSectionInHomeThunk({
				...data,
				//  id: params?.sectionId,
				type: "who_are_we",
				// id: targetEdit?.id ? targetEdit?.id : null,
			})
		)
			.unwrap()
			.then((data) => {
				setFunctionWithEdit();
				dispatch(
					AllMainSectionDataThunk({
						page: pageTarget,
						type: "who_are_we",
					})
				);
			})
			.catch((error) => {
				if (error?.data) {
					watch(`ingredients`)?.forEach((el, index) => {
						if (error?.data?.link) {
							// console.log(error?.data["slider.link"]);
							setError(`ingredients.${index}.link`, {
								message: error?.data?.link,
							});
						}
						if (error?.data?.file) {
							// console.log(error?.data["slider.link"]);
							setError(`ingredients.${index}.file`, {
								message: error?.data?.file,
							});
						}
						if (error?.data?.main_title) {
							setError(`ingredients.${index}.main_title`, {
								message: error?.data?.main_title,
							});
						}
						if (error?.data?.sub_title) {
							setError(`ingredients.${index}.sub_title`, {
								message: error?.data?.sub_title,
							});
						}
					});
				}
			});
	};
	const programAdvantageBlock = () => {
		return (
			ingredientsFields?.length > 0 && (
				<>
					{ingredientsFields.map((block, blockIndex) => {
						return (
							<>
								{/* Create program items  */}
								<>
									<section className='py-[15px] px-[25px]' key={blockIndex}>
										<SectionLabel label={"عنوان"} icon={<TextIcon />} />
										<div className='w-[94%] min-h-[56px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow flex justify-start items-center '>
											<input
												type='text'
												{...register(`ingredients.${blockIndex}.title`)}
												className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
											/>
										</div>
										{errors?.ingredients &&
											errors?.ingredients[blockIndex]?.title && (
												<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
													<span>
														{errors?.ingredients[blockIndex]?.title?.message}
													</span>
												</div>
											)}
									</section>
									{/* Upload Videos or images  */}
									<section className='py-[15px] px-[25px]'>
										<SectionLabel label={"صورة/ فيديو"} icon={<ImagesIcon />} />

										{/* Upload video or video Component */}
										<div className='w-[94%]  flex justify-start items-center gap-4'>
											{/* upload images Component*/}
											<div className='w-[94%] min-h-[100px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow p-4'>
												<div className='w-full  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
													<input
														type='file'
														accept='image/*,video/*'
														control={control}
														onChange={(selectedImageOrVideo) => {
															if (selectedImageOrVideo?.target?.files[0]) {
																setError(`ingredients.${blockIndex}.file`);
																handleSetImageInput(
																	blockIndex,
																	selectedImageOrVideo
																);
															}
														}}
														ref={fileInputRef}
														name='file'
														/* hidden this input and use icon below to upload  */
														style={{ display: "none" }}
													/>

													{/* Button to trigger file input */}
													<button
														type='button'
														onClick={handleButtonClick}
														className='w-full h-full flex justify-center items-center'>
														{targetEdit?.type &&
														typeof watch(`ingredients.${0}.file`) == "string" &&
														targetEdit?.id ? (
															<div className='p-1 w-full h-full flex justify-center items-center'>
																<img
																	src={watch(
																		`ingredients.${blockIndex}.previewImgOrVideo`
																	)}
																	alt={watch(
																		`ingredients.${blockIndex}.previewImgOrVideo`
																	)}
																	className='h-full '
																/>
															</div>
														) : block?.file ? (
															block?.previewImgOrVideo !== null &&
															(block?.previewImgOrVideo?.endsWith(".png") ||
																block?.file?.type?.startsWith("image/")) ? (
																<div className='p-1 w-full h-full flex justify-center items-center'>
																	<img
																		src={block?.previewImgOrVideo}
																		alt={block?.previewImgOrVideo}
																		className='h-full '
																	/>
																</div>
															) : (
																<div className='p-1 w-full h-full flex justify-center items-center'>
																	{block?.previewImgOrVideo}
																</div>
															)
														) : (
															<UploadImagesIcon
																className='w-6 h-6'
																title='تغيير صورة '
															/>
														)}
													</button>
												</div>

												{/* Image Size */}
												<div className=''>
													<span className='text-[16px] text-[#C6C6C6] font-normal '>
														المقاس المقترح: 400 * 180
													</span>
												</div>
											</div>

											{/* Save and delete videos */}
											<div className='flex justify-start items-center gap-4'>
												{/* preview file */}
												{targetEdit?.type &&
												typeof watch(`ingredients.${0}.file`) == "string" &&
												targetEdit?.id
													? watch(`ingredients.${blockIndex}.file`) !== null &&
													  watch(
															`ingredients.${blockIndex}.previewImgOrVideo`
													  )?.endsWith(".mp4") &&
													  watch(
															`ingredients.${blockIndex}.previewImgOrVideo`
													  ) !== null && (
															<button
																type='button'
																onClick={() => {
																	// console.log(block?.previewImgOrVideo);
																	handleOpenPreviewVideoModal(
																		watch(
																			`ingredients.${blockIndex}.previewImgOrVideo`
																		)
																	);
																}}
																className='block cursor-pointer disabled:cursor-not-allowed'>
																<PreviewIcon
																	className='w-11 h-11 preview-video-icon'
																	title='معاينة الفيديو'
																/>
															</button>
													  )
													: block?.file !== null &&
													  (block?.previewImgOrVideo?.endsWith(".mp4") ||
															block?.file?.type?.startsWith("video/")) &&
													  block?.previewImgOrVideo !== null && (
															<button
																type='button'
																onClick={() => {
																	console.log(block?.previewImgOrVideo);
																	handleOpenPreviewVideoModal(
																		block?.previewImgOrVideo
																	);
																}}
																className='block cursor-pointer disabled:cursor-not-allowed'>
																<PreviewIcon
																	className='w-11 h-11 preview-video-icon'
																	title='معاينة الفيديو'
																/>
															</button>
													  )}
												{/* delete video */}
												<button
													type='button'
													className='cursor-pointer disabled:cursor-not-allowed'
													onClick={() => handleDeleteImages(blockIndex)}>
													<DeleteIcon
														className='w-6 h-6 '
														title='حذف الفيديو'
													/>
												</button>
											</div>
										</div>
										{errors?.ingredients &&
											errors?.ingredients[blockIndex]?.file && (
												<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
													<span>
														{errors?.ingredients[blockIndex]?.file?.message}
													</span>
												</div>
											)}
									</section>
									{/* Embed YouTube Video  */}
									<section className='py-[15px] px-[25px]'>
										<SectionLabel
											label={"رابط فيديو"}
											icon={<LinkIcon className='link-icon' />}
										/>

										<div className='w-[94%]  min-h-[100px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow px-4 flex justify-center items-center'>
											<input
												type='text'
												name='link'
												// value={block?.link}
												{...register(`ingredients.${blockIndex}.link`)}
												// onChange={(e) => handleSetLinkInput(e, blockIndex)}
												placeholder={"https://www.kkk.youtube.com"}
												className='w-full h-11 bg-white m-auto p-5 !border-[1px] !border-solid !border-unnamed_color_119184  rounded-lg'
											/>
										</div>
										{errors?.ingredients &&
											errors?.ingredients[blockIndex]?.link && (
												<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
													<span>
														{errors?.ingredients[blockIndex]?.link?.message}
													</span>
												</div>
											)}
									</section>
								</>
							</>
						);
					})}
				</>
			)
		);
	};

	useEffect(() => {
		return () => {
			dispatch(setSectionDataState());
			dispatch(setCurrentSectionDataStateRow());
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | إعدادات الصفحة الرئيسية</title>
			</Helmet>
			<div className='py-4 px-14'>
				{/*  Pages Bread crumbs */}
				<section>
					<PagesBreadcrumbs
						parentPage={"الرئيسية"}
						currentPage={`${name}`}
						isNested={true}
						route={"/website-home-page"}
					/>
				</section>
				{reload ? (
					<Loader />
				) : (
					<form className='p-[25px]' onSubmit={handleSubmit(onSubmit)}>
						{section_type?.main_title == true && (
							<>
								<SectionLabel label={"عنوان"} icon={<TextIcon />} />
								<div className=' min-h-[56px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow flex justify-start items-center '>
									<input
										type='text'
										className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
									/>
								</div>
							</>
						)}

						{programAdvantageBlock()}
						<div className='py-[15px] '>
							{" "}
							<TextEditorWithForm
								placeholder={" المحتوى النصي للقسم المحتوى"}
								height={"300px"}
								setValue={setValue}
								watch={watch}
								register={register}
								name='text'
							/>
							<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
								<span>{errors?.text?.message}</span>
							</div>
						</div>
						<div>
							{!targetEdit?.type && (
								<button
									title='اضافه سلايد جديد'
									type='submit'
									onClick={() => {
										window.scroll(0, 0);
									}}
									className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-white border-unnamed_color_055345  bg-unnamed_color_119184   rounded  cursor-pointer'>
									<>
										حفظ
										<PlusIcon className='add-icon' />
									</>
								</button>
							)}
							{targetEdit?.type && (
								<div className=' flex justify-center items-center w-full gap-[20px]'>
									<button
										title='تعديل سلايد'
										type='submit'
										onClick={() => {
											window.scroll(0, 0);
										}}
										className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-white border-unnamed_color_055345  bg-unnamed_color_119184   rounded  cursor-pointer'>
										<>
											حفظ
											<SaveAs className='add-icon' />
										</>
									</button>

									<div
										title='الغاء التعديل'
										onClick={() => {
											setFunctionWithEdit();
										}}
										className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-white border-unnamed_color_055345  bg-unnamed_color_119184   rounded  cursor-pointer'>
										<>
											الغاء التعديل
											<Close className='add-icon' />
										</>
									</div>
								</div>
							)}
						</div>
					</form>
				)}
				<div className=' w-full h-[2px] bg-unnamed_color_C6C6C6 mb-[40px] mt-[60px]'></div>
			</div>
			<PreviewVideoModal
				{...{ openPreviewModal, setOpenPreviewModal, videoSrc, setVideoSrc }}
			/>
		</>
	);
};

export default HomeWhoAreWeSection;
