import React, { useContext, useState } from "react";
import Context from "../../../context/Context";
import { Helmet } from "react-helmet";
import { PagesBreadcrumbs } from "../../../components";
import { CustomerServiceInfoModal } from "../customerService";
import OrdersTable from "./OrdersTable";

const Orders = () => {
	// this context to open and close customer service info modal
	const customerServiceModalContext = useContext(Context);
	const { openCustomerServiceModal } = customerServiceModalContext;

	const [pageTarget, setPageTarget] = useState(1);
	const [rowsCount, setRowsCount] = useState(10);

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | طلبات سيارة ذوي الإعاقه </title>
			</Helmet>

			<div className='py-4 px-14 w-full'>
				{/* Pages Bread crumbs */}
				<section>
					<PagesBreadcrumbs
						currentPage={"طلبات سيارة ذوي الإعاقه "}
						parentPage={null}
						isNested={false}
					/>
				</section>
				{/* Customer Service Table component */}
				<section className='mb-6'>
					<OrdersTable
						pageTarget={pageTarget}
						setPageTarget={setPageTarget}
						rowsCount={rowsCount}
						setRowsCount={setRowsCount}
					/>
				</section>
				{/* Customer Service Info Modal */}
				{openCustomerServiceModal && (
					<CustomerServiceInfoModal
						pageTarget={pageTarget}
						rowsCount={rowsCount}
						order={true}
					/>
				)}
			</div>
		</>
	);
};
export default Orders;
