import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//
export let OrdersThunk = createAsyncThunk(
	"OrdersThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(
				`/api/admin/orders?page=${arg.page}&per_page=${arg.number}`
			);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

// confirm order
export const ConfirmOrderThunk = createAsyncThunk(
	"ConfirmOrderThunk",

	async ({ id, accept }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`/api/admin/orders/${id}`, { accept });
			return response.data;
		} catch (error) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			} else {
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);

// cancel order
export const FinishOrderThunk = createAsyncThunk(
	"finishOrderThunk",

	async ({ id, accept }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`/api/admin/orders/${id}/finish`, {
				accept,
			});
			return response.data;
		} catch (error) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			} else {
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);
