import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AddPageThunk = createAsyncThunk(
	"pages/AddPageThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			// Create a new FormData object
			let formData = new FormData();
			if (arg?.page_icon?.name) {
				formData.append("icon", arg?.page_icon);
			}
			if (arg?.parent_page_id !== "main") {
				// console.log(arg?.parent_page_id)
				formData.append("parent_page_id", arg?.parent_page_id);
			}
			if (arg?.parent_page_id == "main") {
				// console.log(arg?.parent_page_id)
				formData.append("parent_page_id", "");
			}

			// Append form data to the FormData object
			formData.append("name", arg?.page_name);
			formData.append("footer", arg?.footer_type);

			let res = await axios.post(
				`/api/admin/pages${arg?.id ? "/" + arg?.id : ""}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
