import React from "react";

const SectionLabel = ({ label, icon, requiredIcon }) => {
	return (
		<div className=' flex justify-start items-center gap-2 mb-2'>
			<span>{icon}</span>
			<h4 className='text-lg text-[#200407] font-medium font-Tajawal'>
				{label}
			</h4>
			<span>{requiredIcon}</span>
		</div>
	);
};

export default SectionLabel;
