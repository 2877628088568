import * as React from "react";
import { useContext } from "react";
import Context from "../../../../context/Context";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// css styles
import { DeleteForever, InfoOutlined } from "@mui/icons-material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContactThunk } from "../../../../RTK/Thunk/ContactThunk";
import { DeleteContactThunk } from "../../../../RTK/Thunk/DeleteContactThunk";
import AlertDialog from "../../../../components/AlertBox/AlertBox";
import EmptyBox from "../../../../components/EmptyBox/EmtyBox";
import PaginationBox from "../../../../components/PaginationBox/PaginationBox";
import { Loader } from "../../../../components/loader";
import "../../../../index.css";

// -----------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#C2D2EA",
		color: "#000",
		fontSize: 18,
		fontWeight: 500,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
		padding: "10px",
	},
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme?.palette?.action?.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const CustomerServiceTable = () => {
	let dispatch = useDispatch();
	const [deleteId, setDeleteId] = React.useState(0);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [pageTarget, setPageTarget] = useState(1);
	const [rowsCount, setRowsCount] = useState(10);
	let { contactData, lastPage, reload, currentPage } = useSelector(
		(state) => state.ContactReducer
	);

	// get contact data
	useEffect(() => {
		dispatch(ContactThunk({ page: pageTarget, number: rowsCount }));
	}, [pageTarget, rowsCount]);

	// this context to open and close customer service info modal
	const customerServiceModalContext = useContext(Context);
	const { setOpenCustomerServiceModal, setCustomerServiceInfo } =
		customerServiceModalContext;

	// ------------------------------------------------------

	/** handle open contact us info modal  */
	const handleOpenCustomerServiceModal = (info) => {
		setOpenCustomerServiceModal(true);
		setCustomerServiceInfo(info);
	};

	// ------------------------------------------------------
	const handleDeleteContact = (id) => {
		dispatch(
			DeleteContactThunk({
				id: id,
			})
		)
			.unwrap()
			.then((data) => {
				dispatch(ContactThunk({ page: pageTarget }));

				// toast.success("تم حذف العنصر بنجاح", {
				// 	theme: "light",
				// });
			})
			.catch((error) => {
				// handle error here
				// toast.error(error, {
				// 	theme: "light",
				// });
			});
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					mb: 2,
					boxShadow: "none",
					borderRadius: "16px",
					padding: "30px 25px",
				}}>
				{/* toolbar */}

				{reload ? (
					<Loader />
				) : contactData.length ? (
					<>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 700 }} aria-label='customized table'>
								<TableHead>
									<TableRow>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											الرقم
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											الاسم
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											البريد الإلكتروني
										</StyledTableCell>

										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											رقم الجوال
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											أجراءات
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{contactData?.map((row, index) => (
										<StyledTableRow key={row.id}>
											<StyledTableCell align='center'>
												{index + 1}
											</StyledTableCell>
											<StyledTableCell align='center'>
												{row.name}
											</StyledTableCell>
											<StyledTableCell align='center'>
												{row.email}
											</StyledTableCell>
											<StyledTableCell
												align='center'
												style={{ direction: "ltr" }}>
												{row.phone}
											</StyledTableCell>
											<StyledTableCell align='center'>
												<div className='action flex items-center justify-center gap-2'>
													<IconButton
														aria-label=''
														onClick={() => {
															handleOpenCustomerServiceModal(row);
														}}>
														<InfoOutlined className='text-[#283078]' />
													</IconButton>
													<IconButton
														aria-label=''
														onClick={() => {
															setOpenAlert(true);
															setDeleteId(row.id);
														}}>
														<DeleteForever className='text-[red]' />
													</IconButton>
												</div>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						{contactData?.length !== 0 && (
							<PaginationBox
								pageCount={lastPage}
								rowsCount={rowsCount}
								currentPage={currentPage}
								setPageTarget={setPageTarget}
								setRowsCount={setRowsCount}
							/>
						)}
					</>
				) : (
					<EmptyBox />
				)}
				<AlertDialog
					open={openAlert}
					setOpen={setOpenAlert}
					handleDelete={handleDeleteContact}
					deleteId={deleteId}
					setDeleteId={setDeleteId}
				/>
			</Paper>
		</Box>
	);
};

export default CustomerServiceTable;
