import { createSlice } from "@reduxjs/toolkit";

import {
	GetAllNotificationsThunk,
	UnreadNotificationsCountThunk,
} from "../Thunk/NotificationsThunk";

let initState = {
	reload: true,
	notifications: [],
	UnreadNotificationsCount: 0,
};

let NotificationsReducer = createSlice({
	name: "Notifications",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======GetAllNotificationsThunk===========
			.addCase(GetAllNotificationsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(GetAllNotificationsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.notifications = action.payload?.data;
			})
			.addCase(GetAllNotificationsThunk.rejected, (state, action) => {
				state.reload = false;
			})
			.addCase(UnreadNotificationsCountThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(UnreadNotificationsCountThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.UnreadNotificationsCount =
					action.payload?.data?.unreadNotificationsCount;
			})
			.addCase(UnreadNotificationsCountThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});

export default NotificationsReducer.reducer;
