import React from "react";

// Third Party
import ReactQuill from "react-quill";

// Css Style
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
// Context

const TextEditorWithForm = ({ placeholder,register, height, setValue, watch, name }) => {
  // To share text editor value
  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    ["italic", "underline", "bold"],
    [{ background: [] }, { color: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ];

  return (
    <ReactQuill
      dir="rtl"
      theme="snow"
      value={watch(name)}
      placeholder={placeholder}
      className="bg-unnamed_color_ebebeb "
      style={{ height: height || "550px" }}
      height={"200px"}
      name={name}
      onChange={(e) => {
      if (e == "<p><br></p>") {
            setValue(name, "");

      }
      else{
                  setValue(name, e);
      }
      }}
      modules={{ toolbar: toolbarOptions }}
    />
  );
};

export default TextEditorWithForm;
