import React from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { BlogIcon, SaveIcon } from "../../assets/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { OneTitleMainPagesSectionThunk } from "../../RTK/Thunk/OneTitleMainPagesSectionThunk ";
import { setCurrentSeCtionTitleState } from "../../RTK/Reducers/HomePageReducer";
import { UpdateTitleMainPagesSectionThunk } from "../../RTK/Thunk/UpdateTitleMainPagesSectionThunk";
const schema = Yup.object().shape({
	title: Yup.string().required("قم بإضافة اسم القسم"),
});
const HomeSectionsTitle = ({ SectionName }) => {
	let dispatch = useDispatch();
	let { currentSeCtionTitle } = useSelector((state) => state.HomePageReducer);
	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			title: "",
		},
	});

	// ====get-one-data====
	useEffect(() => {
		dispatch(OneTitleMainPagesSectionThunk({ type: SectionName }));
	}, [SectionName]);
	//set currentValue
	const currentSectionDataRef = React.useRef(true);
	useEffect(() => {
		if (currentSeCtionTitle !== null && currentSectionDataRef.current) {
			setValue("title", currentSeCtionTitle[SectionName]);
			//  setValue("title", currentSeCtionTitle?.title);
			currentSectionDataRef.current = false;
		}
	}, [currentSeCtionTitle, currentSectionDataRef, setValue]);
	//  reset data
	useEffect(() => {
		return () => {
			dispatch(setCurrentSeCtionTitleState());
			// dispatch(setCurrentSectionDataStateRow());
		};
	}, []);
	const onSubmit = async (data) => {
		console.log(data);
		dispatch(
			UpdateTitleMainPagesSectionThunk({
				...data,
				type: SectionName,
			})
		)
			.unwrap()
			.then((data) => {
				// dispatch(
				//   AllMainSectionDataThunk({
				//     page: pageTarget,
				//     type: "testimonials",
				//   })
				// );
				//  setShowModalAddSection({ show: false, type: "" });
			})
			.catch((error) => {
				if (error?.data) {
					if (error?.data?.title) {
						// console.log(error?.data["slider.link"]);
						setError(`title`, {
							message: error?.data.title,
						});
					}
					// if (error?.data["slider.title"]) {
					//   // console.log(error?.data["slider.link"]);
					//   setError(`ingredients.${0}.title`, {
					//     message: error?.data["slider.title"],
					//   });
					// }
				}

				// console.log(error);
				// handle error here
			});
	};
	return (
		<>
			<form
				className='py-[15px]  mt-[20px]  flex justify-center items-start gap-[20px]'
				onSubmit={handleSubmit(onSubmit)}>
				<div className='w-full'>
					<div className='w-full border-[1px] rounded-[2px] border-unnamed_color_C6C6C6 h-14 flex justify-start items-center gap-3 p-3 bg-unnamed_color_f6f6f6 add-new-section-title-shadow '>
						<BlogIcon className='blog-icon' />
						<input
							className='w-full h-full bg-transparent placeholder:text-[#C6C6C6] text-lg font-normal text-black'
							type='text'
							placeholder='اسم القسم'
							{...register("title")}
						/>
					</div>
					<div className=' w-full  flex justify-start  items-start gap-[6px] text-ured'>
						<span>{errors?.title?.message}</span>
					</div>
				</div>
				<button
					type='submit'
					className='w-[154px] my-0 h-14 m-auto flex justify-center items-center gap-2  cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_119184 rounded '
					// onClick={handleAddNewImgOrVideoAndTestToCurrentArray}
				>
					<span>
						<SaveIcon />
					</span>
					<span className='text-white text-lg font-medium '>حفظ</span>
				</button>
			</form>
			<div className=' w-full h-[2px] bg-unnamed_color_C6C6C6 mb-[30px] mt-[30px]'></div>
		</>
	);
};

export default React.memo(HomeSectionsTitle);
