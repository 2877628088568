import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
	const location = useLocation();

	const token = localStorage.getItem("token");

	return token ? (
		children
	) : (
		<Navigate state={{ from: location }} replace to='/login' />
	);
};

export default PrivateRoute;
