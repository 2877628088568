import React, { Fragment, useContext, useState } from "react";
import axios from "axios";
import ReactDom from "react-dom";
import Context from "../../../context/Context";

// import css styles

import styles from "./ConfirmDeleteAction.module.css";

// icon
import { CloseIcon, WarningIcon } from "../../../assets/icons";

const ConfirmDeleteAction = () => {
	// to handle show and hidden actions (save or delete modal) alert
	const [loading, setLoading] = useState(false);
	const actionModalContext = useContext(Context);
	const {
		deleteUrl,
		deleteMethod,
		setDeleteUrl,
		deleteReload,
		setIsConformed,
		setActionTitle,
		setDeleteReload,
		isDeleteAllItems,
		theItemToBeDeleted,
		setTheItemToBeDeleted,
	} = actionModalContext;
	// -----------------------------------------------------------------

	// Confirm Delete Single Item
	const confirmDeleteAction = () => {
		if (deleteMethod === "delete") {
			setIsConformed(true);
			setLoading(true);
			axios
				.delete(deleteUrl, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer  ${localStorage.getItem("token")}`,
					},
				})
				.then((res) => {
					if (res?.status === 200 && res?.statusText === "OK") {
						setActionTitle(res?.data?.message?.ar);
						setDeleteReload(!deleteReload);
						setTheItemToBeDeleted(null);
						setIsConformed(false);
						setDeleteUrl(null);
						setLoading(false);
					} else {
						setActionTitle(res?.data?.message?.ar);
						setDeleteReload(!deleteReload);
						setTheItemToBeDeleted(null);
						setIsConformed(false);
						setDeleteUrl(null);
						setLoading(false);
					}
				});
		} else {
			setIsConformed(true);
			setLoading(true);
			axios
				.get(deleteUrl, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer  ${localStorage.getItem("token")}`,
					},
				})
				.then((res) => {
					if (res?.status === 200 && res?.statusText === "OK") {
						setActionTitle(res?.data?.message?.ar);
						setDeleteReload(!deleteReload);
						setTheItemToBeDeleted(null);
						setIsConformed(false);
						setDeleteUrl(null);
						setLoading(false);
					} else {
						setActionTitle(res?.data?.message?.ar);
						setDeleteReload(!deleteReload);
						setTheItemToBeDeleted(null);
						setIsConformed(false);
						setDeleteUrl(null);
						setLoading(false);
					}
				});
		}
	};

	return (
		<div>
			{/* Backdrop */}
			<div className={`${styles.backdrop} z-50`} />

			{/* Modal Body  */}
			<div
				className={` ${styles.z_index_100} fixed left-0 top-32 translate-x-1/2 inset-1/2  w-[494px] h-[245px] bg-[#FFFFFF] rounded-[10px] `}>
				{/* content of modal */}
				<section className='w-full h-full'>
					{/* close icon */}
					<div className='w-full flex justify-end items-center mb-4 p-2'>
						<CloseIcon
							onClick={() => setTheItemToBeDeleted(null)}
							className=' cursor-pointer'
						/>
					</div>

					{/* confirm message */}
					<div className='w-full h-full flex flex-col items-center'>
						<div className='flex flex-col items-center mb-10'>
							<WarningIcon className=' w-9 h-9 mb-4' />
							<h5 className=' text-xl font-medium text-[#200407]'>
								هل أنت متأكد من حذف {theItemToBeDeleted}
							</h5>
						</div>

						{/* confirm button*/}
						<button
							disabled={loading}
							onClick={() =>
								isDeleteAllItems ? setIsConformed(true) : confirmDeleteAction()
							}
							className='w-[154px] h-[56px] flex justify-center items-center bg-[#30506Bff] text-[#FFFFFF] text-lg font-medium  rounded cursor-pointer'>
							تأكيد
						</button>
					</div>
				</section>
			</div>
		</div>
	);
};

const ConfirmDeleteActionModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<ConfirmDeleteAction />,
				document.getElementById("confirm-delete-action-modal")
			)}
		</Fragment>
	);
};

export default ConfirmDeleteActionModal;
