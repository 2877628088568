import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// GetAllNotificationsThunk
export let GetAllNotificationsThunk = createAsyncThunk(
	"GetAllNotificationsThunk",
	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`/notifications`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

// unread_notifications_count
export let UnreadNotificationsCountThunk = createAsyncThunk(
	"GetAllNotificationsThunk/UnreadNotificationsCountThunk",
	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`/notifications/unread_notifications_count`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

// Mark all notifications as read
export let MarkAllNotificationsAsReadThunk = createAsyncThunk(
	"MarkAllNotificationsAsReadThunk",
	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.patch(`/notifications`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

// Mark one notifications as read by id
export let MarkOneNotificationsAsReadByIdThunk = createAsyncThunk(
	"MarkOneNotificationsAsReadByIdThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.patch(`/notifications/${arg?.id}`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

// delete all notifications as read
export let DeleteAllNotificationsAsReadThunk = createAsyncThunk(
	"DeleteAllNotificationsAsReadThunk",
	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.delete(`/notifications`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

// delete one notifications as read by id
export let DeleteNotificationsAsReadByIdThunk = createAsyncThunk(
	"GetAllNotificationsThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.delete(`/notifications/${arg?.id}`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
