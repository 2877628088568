import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let ChangeStatusMainPageThunk = createAsyncThunk(
  "/ChangeStatusMainPageThunk",
  async (arg, ThunkApi) => {
    // console.log(arg);
    let { rejectWithValue } = ThunkApi;
    try {
      let res = await axios.patch(`/api/main_page/${arg?.type}`, );
      // console.log(res.data);

      return res.data;
    } catch (error) {
    //   console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
