import React, { useContext } from "react";

// Third Party
import ReactQuill from "react-quill";

// Css Style
import "./TextEditor.css";
import "react-quill/dist/quill.snow.css";
// Context
import Context from "../../context/Context";

const TextEditor = ({ placeholder, height }) => {
	// To share text editor value
	const editorContent = useContext(Context);
	const { setEditorValue, editorValue } = editorContent;

	const toolbarOptions = [
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ align: [] }],
		["italic", "underline", "bold"],
		[{ background: [] }, { color: [] }],
		[{ list: "ordered" }, { list: "bullet" }],
		["link", "image"],
	];

	return (
		<ReactQuill
			dir='rtl'
			theme='snow'
			value={editorValue}
			placeholder={placeholder}
			className="bg-unnamed_color_ebebeb "
			style={{ height: height||"550px" }}
			height={"200px"}
			onChange={setEditorValue}
			modules={{ toolbar: toolbarOptions }}
		/>
	);
};

export default TextEditor;
