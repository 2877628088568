// src/notificationService.js
import { messaging, getToken } from "./firebase";

const requestFirebaseNotificationPermission = async () => {
	try {
		const permission = await Notification.requestPermission();
		if (permission === "granted") {
			const token = await getToken(messaging, {
				vapidKey:
					"UOCj5FRzhxMDTx5Lhxl9pub85qpgVY4Nxm0OJUbk4PTvLMmWe4pEAm7pSyZVzxg4YSVPSD1ZQ",
			});
			console.log("Firebase Token:", token);
			return token;
			// Save the token to your database if needed
		} else {
			console.error("Notification permission not granted");
			return null;
		}
	} catch (error) {
		console.error("Error getting permission or token", error);
		return null;
	}
};

export { requestFirebaseNotificationPermission };
