import { createSlice } from "@reduxjs/toolkit";

import { ContactThunk } from "../Thunk/ContactThunk";
import { DeleteContactThunk } from "../Thunk/DeleteContactThunk";

let initState = {
	reload: true,
	code: null,
	contactData: [],
	currentPage: 1,
	lastPage: 1,

	brandImg: "",
};

let ContactReducer = createSlice({
	name: "contact",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======contact===========
			.addCase(ContactThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ContactThunk.fulfilled, (state, action) => {

				state.reload = false;
				state.contactData = action.payload?.data;
				state.currentPage = action.payload?.meta?.current_page;
				state.lastPage = action.payload?.meta?.last_page;
			})
			.addCase(ContactThunk.rejected, (state, action) => {
				state.reload = false;
			})

			// Handle the deleteContact action
			.addCase(DeleteContactThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(DeleteContactThunk.fulfilled, (state) => {
				state.reload = false;
				// You may want to update other state properties here based on the delete action
			})
			.addCase(DeleteContactThunk.rejected, (state) => {
				state.reload = false;
				// Handle the rejection if needed
			});
	},
});

export default ContactReducer.reducer;

// export { }=LoginReducer.actions
