import React, { useState } from "react";

//  MUI
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

// Components
import { Inputs, SectionLabel, UploadImages } from "../../../../components";

// icons
import { ImagesIcon, StarIcon, TextIcon } from "../../../../assets/icons";

// css styles
import "../../../../index.css";

// handle according
const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: "transparent",
	"&:not(:last-child)": {
		borderBottom: 0,
		marginBottom: "0",
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme }) => ({
		width: "100%",
		backgroundColor: "#FFF",
		marginBottom: "0",
		flexDirection: "row",
		minHeight: "60px",
		border: "1px solid #EEEEEE",
		borderRadius: " 4px",

		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(-90deg)",
		},
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(1),
		},
	})
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	border: "none",
	padding: "0px",
}));
//---------------------------------------------------------------------------------------------

//  Customer service UI Component
export const CustomerServiceUIComponent = () => {
	const [mainImage, setMainImage] = useState(null);
	const [mainTitle, setMainTitle] = useState("");

	const [customerServiceValues, setCustomerServiceValues] = useState({
		fullName: "",
		phoneNumber: "",
		email: "",
		message: "",
	});

	// handle onchange values
	const handleCustomerServicesValues = (e) => {
		const { name, value } = e.target;

		setCustomerServiceValues((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	// --------------------------------------------------

	return (
		<div className='bg-white w-full rounded-lg py-5 px-10 border-[1px] border-solid border-[#0000000F] add-new-section-title-shadow '>
			{/* page title */}
			<section className='mb-6'>
				<SectionLabel label={"عنوان الصفحة"} icon={<TextIcon />} />

				<Inputs
					width={"w-[90%]"}
					type={"text"}
					placeholder='الأسئلة الشائعة حول برنامج الرحلة التجارية إلى الصين'
					value={mainTitle}
					setValue={setMainTitle}
				/>
			</section>

			{/* Main Title of page */}
			<section className='mb-6'>
				<SectionLabel
					label={"خلفية الترويسة العلوية الرئيسي"}
					icon={<ImagesIcon />}
				/>

				{/* upload images Component*/}
				<UploadImages
					width={"w-[90%]"}
					image={mainImage}
					setImage={setMainImage}
					ImageSize={"المقاس المقترح: 430 * 110"}
				/>
			</section>

			{/* full name */}
			<section className='mb-6'>
				<SectionLabel label={"الاسم الثلاثي"} icon={<StarIcon />} />

				<input
					type='text'
					name='fullName'
					placeholder='يقوم المشترك بكتابة اسمه ثلاثي'
					value={customerServiceValues?.fullName}
					onChange={handleCustomerServicesValues}
					className=' w-[90%] h-[56px] rounded-[10px] bg-[#C2D2EA] section-box-shadow focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal p-2 '
				/>
			</section>

			{/* Phone number */}
			<section className='mb-6'>
				<SectionLabel label={"رقم الجوال"} icon={<StarIcon />} />

				<div
					className={`w-[90%] h-[56px] flex justify-start items-center  rounded-[10px] bg-[#C2D2EA] section-box-shadow  pl-2`}>
					<input
						type='text'
						name='phoneNumber'
						value={customerServiceValues?.phoneNumber}
						onChange={handleCustomerServicesValues}
						placeholder='05055606'
						className={` w-full h-full focus:outline-none placeholder:text-[#C6C6C6] bg-transparent  text-[16px] font-normal font-Tajawal p-2 `}
					/>

					{/* Country key */}
					<div className=''>966</div>
				</div>
			</section>

			{/* Email */}
			<section className='mb-6'>
				<SectionLabel label={"البريد الالكتروني"} icon={<StarIcon />} />

				<input
					type='email'
					name='email'
					placeholder='sample@gmail.com'
					value={customerServiceValues?.email}
					onChange={handleCustomerServicesValues}
					className=' w-[90%] h-[56px] rounded-[10px] bg-[#C2D2EA] section-box-shadow focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal p-2 '
				/>
			</section>

			{/* Message content */}
			<section className='mb-6'>
				<SectionLabel label={" نص الرسالة"} icon={<StarIcon />} />

				<textarea
					name='message'
					value={customerServiceValues?.message}
					onChange={handleCustomerServicesValues}
					className=' w-[90%] h-[56px] resize-none rounded-[10px] bg-[#C2D2EA] section-box-shadow focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal p-2 '
				/>
			</section>
		</div>
	);
};

const CustomerServiceUIData = () => {
	// according
	const [expanded, setExpanded] = React.useState("");
	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	return (
		<section className='w-full  '>
			{/* according content */}
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}>
				<AccordionSummary
					aria-controls='panel1d-content'
					id='panel1d-header'
					expandIcon={
						<ArrowForwardIosSharpIcon
							sx={{
								fontSize: "1rem",
								color: "#000",
								transform: "rotate(180deg)",
							}}
						/>
					}>
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: "500",
							fontFamily: "Tajawal",
							color: "#000",
						}}>
						بيانات واجهة خدمة العملاء
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{/* Customer service UI Component */}
					<CustomerServiceUIComponent />
				</AccordionDetails>
			</Accordion>
		</section>
	);
};
export default CustomerServiceUIData;
