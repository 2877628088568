// import all  icons
import { FaCartArrowDown } from "react-icons/fa6";
import { BlogIcon, FormIcon, HomeIcon, Support } from "../assets/icons";

// dashboard routes and sections
export const dashboardPages = [
	{
		id: 1,
		navigateTo: "/website-home-page",
		pageTitle: "الصفحة الرئيسية للموقع",
		icon: <HomeIcon />,
	},

	{
		id: 2,
		navigateTo: "/create-page",
		pageTitle: "إنشاء الصفحات",
		icon: <BlogIcon />,
	},

	{
		id: 9,
		navigateTo: "/customer-service",
		pageTitle: "خدمة العملاء",
		icon: <Support />,
	},

	{
		id: 10,
		navigateTo: "/orders",
		pageTitle: "طلبات السيارات ",
		icon: <FaCartArrowDown style={{ color: "#fff" }} />,
	},
	{
		id: 11,
		navigateTo: "/contact-info",
		pageTitle: "إعدادت تواصل معنا",
		icon: <FormIcon />,
	},
];
