import { createSlice } from "@reduxjs/toolkit";
import { AllMainPagesSectionThunk } from "../Thunk/AllMainPagesSectionThunk";
import { AllMainSectionDataThunk } from "../Thunk/AllMainSectionDataThunk";
import { OneMainPagesSectionThunk } from "../Thunk/OneMainPagesSectionThunk";
import { OneTitleMainPagesSectionThunk } from "../Thunk/OneTitleMainPagesSectionThunk ";

let initState = {
	section_type: { link: "true" },
	mainPageStatus: {},
	currentSectionData: null,
	reload: true,
	code: null,
	SectionData: [],
	currentPage: 1,
	// currentSeCtionTitle: "fk-title",
	currentSeCtionTitle: null,
	lastPage: 1,
};

let HomePageReducer = createSlice({
	name: "mainPage",

	initialState: initState,
	reducers: {
		changePageDataAction: (state, action) => {
			if (action.payload?.name == "news") {
				state.section_type = {
					type: "news",
					main_title: true,
					link: true,
					img: true,
					text: true,
				};
			}
			if (action.payload?.name == "type") {
				state.section_type = {
					type: "type",
					main_title: true,
					link: true,
					img: true,
					text: true,
					icon: true,
					title_02: true,
					// title_02: ,
					video: true,
					customer_name: true,
					customer_info: true,
				};
			}
			if (action.payload?.name == "hero") {
				state.section_type = { type: "hero", link: true, img: true };
			}
			if (action.payload?.name == "services") {
				state.section_type = {
					type: "services",
					main_title: true,
					icon: true,
					title_02: true,
				};
			}
			if (action.payload?.name == "achievements") {
				state.section_type = {
					type: "achievements",
					main_title: true,
					icon: true,
					title_02: true,
					text: true,
				};
			}
			if (action.payload?.name == "AboutUs") {
				state.section_type = {
					type: "AboutUs",
					main_title: true,
					icon: true,
					// title_02: ,
					text: true,
				};
			}
			if (action.payload?.name == "photos") {
				state.section_type = {
					type: "photos",
					main_title: true,
					img: true,
					text: true,
				};
			}
			if (action.payload?.name == "videos") {
				state.section_type = {
					type: "videos",
					main_title: true,
					video: true,
					text: true,
				};
			}
			if (action.payload?.name == "about") {
				state.section_type = {
					type: "about",
					main_title: true,
					img: true,
					text: true,
					customer_name: true,
					customer_info: true,
				};
			}
			if (action.payload?.name == "partner") {
				state.section_type = {
					type: "partner",
					main_title: true,
					img: true,
					title_02: true,
				};
			}
			if (action.payload?.name == "remove") {
				state.section_type = {};
			}
		},

		changePageStatusAction: (state, action) => {
			// console.log(action.payload?.type);
			// state.mainPageStatus.[type]
			// console.log(state.mainPageStatus[action.payload?.type]);
			if (state.mainPageStatus[action.payload?.type] == 1) {
				state.mainPageStatus[action.payload?.type] = 0;
				// console.log(state.mainPageStatus[action.payload?.type]);
			} else if (state.mainPageStatus[action.payload?.type] == 0) {
				state.mainPageStatus[action.payload?.type] = 1;
				// console.log(state.mainPageStatus[action.payload?.type]);
			}
			// console.log(state.mainPageStatus[action.payload?.type]);
		},
		setCurrentSectionDataStateRow: (state, action) => {
			state.currentSectionData = null;
		},
		setSectionDataState: (state, action) => {
			state.SectionData = [];
		},
		setCurrentSeCtionTitleState: (state, action) => {
			state.currentSeCtionTitle = null;
		},
	},
	extraReducers: (builder) => {
		builder
			// =======AllMainSectionDataThunk===========
			.addCase(AllMainSectionDataThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AllMainSectionDataThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.SectionData = action.payload?.data;
				state.currentPage = action.payload?.meta?.current_page;
				state.lastPage = action.payload?.meta?.last_page;
			})
			.addCase(AllMainSectionDataThunk.rejected, (state, action) => {
				state.reload = false;
			})
			// =======AllMainPagesSectionThunk===========

			.addCase(AllMainPagesSectionThunk.pending, (state, action) => {})
			.addCase(AllMainPagesSectionThunk.fulfilled, (state, action) => {
				// console.log(action.payload);
				state.mainPageStatus = action.payload?.data;
			})
			.addCase(AllMainPagesSectionThunk.rejected, (state, action) => {})
			// =======OneSectionThunk data===========
			.addCase(OneMainPagesSectionThunk.pending, (state, action) => {})
			.addCase(OneMainPagesSectionThunk.fulfilled, (state, action) => {
				state.currentSectionData = action.payload?.data;
			})
			.addCase(OneMainPagesSectionThunk.rejected, (state, action) => {})
			// =======OneSectionThunk data===========
			.addCase(OneTitleMainPagesSectionThunk.pending, (state, action) => {})
			.addCase(OneTitleMainPagesSectionThunk.fulfilled, (state, action) => {
				state.currentSeCtionTitle = action.payload?.data;
			})
			.addCase(OneTitleMainPagesSectionThunk.rejected, (state, action) => {
				state.currentSeCtionTitle = null;
			});
	},
});

export default HomePageReducer.reducer;

export let {
	closeModal,
	changePageStatusAction,
	setCurrentSectionDataStateRow,
	closeError,
	changePageDataAction,
	setSectionDataState,
	setCurrentSeCtionTitleState,
} = HomePageReducer.actions;
