import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AddImageVideoTitleSectionThunk = createAsyncThunk(
  "section/AddImageVideoTitleSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let formData = new FormData();
      formData.append("title", arg?.section_title);
      formData.append("type", arg?.type);
      formData.append("image_video_title[text]", arg?.text);
      if (typeof arg?.ingredients[0]?.image !== "string") {
              formData.append(
                "image_video_title[file]",
                arg?.ingredients[0]?.image
              );

      }
      formData.append("image_video_title[title]", arg?.ingredients[0]?.title);
      // formData.append("image_video_title[link]", arg?.ingredients[0]?.link);
    //  if (arg?.ingredients[0]?.link) {
       formData.append("image_video_title[link]", arg?.ingredients[0]?.link);
    //  }
      //api/admin/pages/4/sections
      let res = await axios.post(
        `/api/admin/pages/${arg?.id}/sections${
          arg?.targetId !== null ? "/" + arg?.targetId : ""
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );
      // `/api/admin/pages/${arg?.id}/sections`,{...arg});

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
