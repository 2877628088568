import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let OneTitleMainPagesSectionThunk = createAsyncThunk(
  "pages/OneTitleMainPagesSectionThunk ",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let url = `/api/main_page_name`;
      // let url = `/api/admin/${arg?.type}`;

      let res = await axios.get(url);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
