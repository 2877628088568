import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Third party
import { Helmet } from "react-helmet";

// import components
import { PagesBreadcrumbs, SectionLabel } from "../../../../components";

// icons
import { SaveIcon } from "../../../../assets/icons";

// import css styes
import { yupResolver } from "@hookform/resolvers/yup";
import { Highlight } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { AddPageThunk } from "../../../../RTK/Thunk/AddPageThunk";
import { OnePageThunk } from "../../../../RTK/Thunk/OnePageThunk";
import { PagesSelectDataThunk } from "../../../../RTK/Thunk/PagesSelectDataThunk";
import SelectSearchBox from "../../../../common/SelectSearchBox/SelectSearchBox";
import "../../../../index.css";
import PageTitle from "../../PageTitle/PageTitle";

// =====validation===========
const schema = Yup.object().shape({
	page_name: Yup.string().required("قم بإضافة اسم الصفحه"),
	page_icon: Yup.mixed().required("قم بإضافة ايقونة الصفحه"),
	footer_type: Yup.string().required("قم بإضافة الصفحه الي الفوتر"),
	parent_page_id: Yup.mixed().required("اختر القسم الذي ستتبعه الصفحه"),
});
const CreateNewBlog = () => {
	const navigate = useNavigate();
	let params = useParams();
	// console.log(params?.pageId)
	let dispatch = useDispatch();
	let { pagesSelectData, currentPageData } = useSelector(
		(state) => state.PageReducer
	);

	const [PageTypeRadio, setPageTypeRadio] = useState("main");
	// ------------------------------------------
	// from hooks
	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			page_name: "",
			page_icon: null,
			footer_type: "do_not_show",
			parent_page_id: null,
		},
	});

	// console.log(errors?.["inputName"]?.message)
	// handle open edit Article page

	useEffect(() => {
		if (!params?.pageId) {
			if (PageTypeRadio == "main") {
				setValue("parent_page_id", "main");
			}
			if (PageTypeRadio == "subsidiary") {
				setValue("parent_page_id", null);
			}
		}
	}, [PageTypeRadio, setValue, params?.pageId]);
	useEffect(() => {
		// errors?.page_icon;
		if (watch("page_icon") !== null || watch("page_icon") !== undefined) {
			setError("page_icon");
		}
	}, [setError, watch("page_icon")]);

	// console.log("page_name",errors?.page_name)
	useEffect(() => {
		dispatch(PagesSelectDataThunk());
	}, []);
	useEffect(() => {
		if (currentPageData !== null) {
			// console.log(currentPageData);
			setValue("page_name", currentPageData?.name);
			setValue("footer_type", currentPageData?.footer);
			if (currentPageData?.parent_page_id) {
				// console.log(currentPageData?.parent_page_id);
				setPageTypeRadio("subsidiary");
				setValue("parent_page_id", String(currentPageData?.parent_page_id));
			} else {
				setPageTypeRadio("main");
				setValue("parent_page_id", "main");
			}
			setValue("page_icon", currentPageData?.icon);
		}
	}, [currentPageData]);
	//  handle update page fun

	useEffect(() => {
		if (params?.pageId) {
			dispatch(OnePageThunk({ id: params?.pageId }));
		}
	}, [params?.pageId]);

	const onSubmit = async (data) => {
		// console.log(data);
		dispatch(
			AddPageThunk(params?.pageId ? { ...data, id: params?.pageId } : data)
		)
			.unwrap()
			.then((data) => {
				navigate("/create-page");
			})
			.catch((error) => {
				console.log(error?.data);
				// setError('registerInput', { type: 'custom', message: 'custom message' });
				if (error?.data?.name) {
					setError("page_name", { message: error?.data?.name });
					// setError('page_icon',);
				}
				if (error?.data?.icon) {
					setError("page_icon", { message: error?.data?.icon });
				}
				if (error?.data?.footer) {
					setError("footer_type", { message: error?.data?.footer });
				}
				if (error?.data?.parent_page_id) {
					setError("parent_page_id", {
						message: error?.data?.parent_page_id,
					});
				}
			});
	};
	return (
		<>
			<Helmet>
				<title>لوحة التحكم | انشاء صفحة جديدة</title>
			</Helmet>

			<div className=' top-[70px] left-0 z-[19] w-full h-full bg-[#FFF] overflow-y-auto pb-20'>
				<form
					className='w-full  m-auto py-4  pr-[25px] pl-60'
					onSubmit={handleSubmit(onSubmit)}>
					{/* Pages Bread crumbs */}
					<section className='mb-10'>
						<PagesBreadcrumbs
							currentPage={
								params?.pageId
									? `تعديل ${currentPageData?.name}`
									: "انشاء صفحة جديدة"
							}
							parentPage={"الصفحات الحالية"}
							isNested={true}
							route={"/create-page"}
						/>
					</section>

					{/* main blog title  */}
					<section className='w-full hidden_body_scroll min-h-[48px]  mb-10'>
						<PageTitle
							setValue={setValue}
							watch={watch}
							register={register}
							errors={errors}
							inputName='page_name'
							iconName='page_icon'
						/>
						{/* Errors*/}
					</section>

					{/* page type  */}
					<div className=' w-full flex justify-center items-start flex-col  mb-[20px] gap-[10px]'>
						<SectionLabel
							label={"  اضافه الصفحه الي الفوتر"}
							icon={<Highlight className='programBlogIcon' />}
						/>
						<RadioGroup
							className='flex  justify-center items-start flex-col gap-[10px]'
							value={watch("footer_type")}
							name='footer_type'
							onChange={(e) => {
								setValue("footer_type", e.target.value);
							}}
							// {...register("footer_type")}
						>
							<FormControlLabel
								value='do_not_show'
								control={
									<Radio
										sx={{
											color: "#283078",
											"&.Mui-checked": {
												color: "#283078",
											},
										}}
									/>
								}
								label='لا تقوم بإضافتها'
							/>
							<FormControlLabel
								value='map'
								control={
									<Radio
										sx={{
											color: "#283078",
											"&.Mui-checked": {
												color: "#283078",
											},
										}}
									/>
								}
								label='اضافه الي خريطة الموقع'
							/>
							<FormControlLabel
								value='quick_links'
								control={
									<Radio
										sx={{
											color: "#283078",
											"&.Mui-checked": {
												color: "#283078",
											},
										}}
									/>
								}
								label='اضافه الي الروابط السريعة'
							/>
						</RadioGroup>
						<div className=' w-full  flex justify-start items-start gap-[6px] text-ured'>
							<span>
								{errors?.footer_type?.type == "required" &&
								errors?.footer_type?.message
									? errors?.footer_type?.message
									: null}
							</span>
						</div>
					</div>
					<div className=' w-full flex justify-center items-start flex-col  mb-[20px] gap-[10px]'>
						<SectionLabel
							label={"حديد نوع الصفحه"}
							icon={<Highlight className='programBlogIcon' />}
						/>

						<RadioGroup
							// defaultValue='null'
							value={PageTypeRadio}
							className='flex  justify-center items-start flex-col gap-[10px]'
							name='parent_page_id'
							onChange={(e) => {
								// console.log(e.target.value)
								if (params?.pageId) {
									if (e.target.value == "main") {
										setValue("parent_page_id", "main");
									}
									if (e.target.value == "subsidiary") {
										setValue("parent_page_id", null);
									}
								}
								setPageTypeRadio(e.target.value);
							}}>
							<FormControlLabel
								value='main'
								control={
									<Radio
										sx={{
											color: "#283078",
											"&.Mui-checked": {
												color: "#283078",
											},
										}}
									/>
								}
								label='الرئيسيه (تعني ان هذه الصفحه ستظهر في القائمه العلويه)'
							/>
							<FormControlLabel
								value='subsidiary'
								control={
									<Radio
										sx={{
											color: "#283078",
											"&.Mui-checked": {
												color: "#283078",
											},
										}}
									/>
								}
								label='فرعيه (تعني ان هذه الصفحه ستتبع قسم محدد)'
							/>
						</RadioGroup>
						{PageTypeRadio === "subsidiary" && (
							<>
								<h6 className=' font-[600] text-[16px]'>
									اختر القسم الذي ستتبعه الصفحه المضافه{" "}
								</h6>
								<SelectSearchBox
									name='parent_page_id'
									errorMsg={errors?.parent_page_id?.message || ""}
									validateStatus={errors?.parent_page_id ? "error" : ""}
									defaultValue=''
									control={control}
									setValue=''
									dataSearch={pagesSelectData}
									placeholder='اختر القسم...'
									label={
										// <p className="select-option-label">
										//   <EditOutlined />
										//   رمز الهاتف
										// </p>
										""
									}
									value={watch}
								/>
								{/* <GlobalSelect selectTitle='اختر القسم' pagesSelectData={pagesSelectData} /> */}
							</>
						)}
						<div className=' w-full  flex justify-start items-start gap-[6px] text-ured'>
							<span>
								{errors?.parent_page_id?.type == "required" &&
								errors?.parent_page_id?.message
									? errors?.footer_type?.message
									: null}
							</span>
						</div>
					</div>

					{/* Save and share buttons  */}
					<section className='flex justify-start items-center gap-5'>
						{/* <button
							disabled={
								!createNewBlogValues?.mainTitle ||
								!createNewBlogValues?.pageContent ||
								!createNewBlogValues?.keywords
							}
							onClick={handleShareBlog}
							className='w-fit h-14 flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-[#30506Bff] rounded '>
							<span>
								<ShareIcon />
							</span>
							<span className='text-white text-lg font-medium  '>
								مشاركة على الموقع
							</span>
						</button> */}

						<button
							// disabled={
							// 	!createNewBlogValues?.mainTitle ||
							// 	!createNewBlogValues?.pageContent ||
							// 	!createNewBlogValues?.keywords
							// }
							// onClick={
							// 	isArticleEdit ? handleEditCurrentArticle : handleSaveBlog
							// }
							type='submit'
							className='w-fit h-14 flex justify-center items-center gap-2 py-[10px] px-[40px] cursor-pointer  bg-unnamed_color_119184  rounded '>
							<span>
								<SaveIcon className=' !text-white' />
							</span>
							{params?.pageId ? (
								<span className='text-white text-lg font-medium '>تعديل</span>
							) : (
								<span className='text-white text-lg font-medium '>حفظ</span>
							)}
						</button>
					</section>
				</form>
			</div>
		</>
	);
};

export default CreateNewBlog;
