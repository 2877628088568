import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// icons
import { ArrowBack, ArrowRight, HomeIcon } from "../../assets/icons";
import { RiArrowLeftSLine } from "react-icons/ri";

const PagesBreadcrumbs = ({ currentPage, parentPage, isNested, route }) => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<div className='flex flex-row items-end mb-8 gap-4'>
			{parentPage && isNested && (
				<>
					{location.pathname === "/edit-my-account" ||
					location.pathname === "/setting-my-account" ? (
						<span
							onClick={() => {
								navigate("/");
							}}
							className='cursor-pointer'>
							<ArrowBack />
						</span>
					) : (
						<span
							onClick={() => {
								navigate("/");
							}}
							className='w-10 h-10 rounded-full bg-unnamed_color_119184 flex justify-center items-center cursor-pointer'>
							<HomeIcon />
						</span>
					)}

					<Link to={route} className=' flex justify-start items-center gap-4'>
						<h4 className=' text-[16px] font-medium text-[#67747B] '>
							{parentPage}
						</h4>
						<RiArrowLeftSLine />
					</Link>
				</>
			)}
			<h4 className='text-[16px] font-medium text-[#707070] '>{currentPage}</h4>
		</div>
	);
};

export default PagesBreadcrumbs;
