import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AddFileSectionThunk = createAsyncThunk(
  "section/AddFileSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let formData = new FormData();

      if (arg?.targetId) {
        formData.append("title", arg?.title);
        formData.append("type", arg?.type);
        arg?.ingredients?.forEach((ingredient, index) => {
          if (
            typeof ingredient?.file !== "string" &&
            ingredient?.file?.length
          ) {
            console.log("ingredient?.file", ingredient?.file);
            formData.append(
              `stored_files[${index}][file]`,
              ingredient?.file[0]
            );
          }
          if (ingredient.status == true) {
            // console.log("status=true");
            formData.append(`stored_files[${index}][status]`, 1);
          }
          if (ingredient.status == false) {
            // console.log("status=false");
            formData.append(`stored_files[${index}][status]`, 0);
          }
          formData.append(`stored_files[${index}][name]`, ingredient.name);
          if (ingredient.id) {
            formData.append(`stored_files[${index}][id]`, ingredient.id);
          }
        });
        // arg?.targetDeleteIdItem=[1,3,5]
        arg?.targetDeleteIdItem?.forEach((id, index) => {
          formData.append(`deleted_files[${index}]`, id);
        });
      } else {
        console.log("else");
        formData.append("title", arg?.title);
        formData.append("type", arg?.type);
        arg?.ingredients?.forEach((ingredient, index) => {
          // if (ingredient.file && ingredient.file !== "[object FileList]") {
          formData.append(`stored_files[${index}][file]`, ingredient?.file[0]);
          // }
          if (ingredient.status == true) {
            // console.log("status=true");
            formData.append(`stored_files[${index}][status]`, 1);
          }
          if (ingredient.status == false) {
            // console.log("status=false");
            formData.append(`stored_files[${index}][status]`, 0);
          }
          formData.append(`stored_files[${index}][name]`, ingredient.name);
        });
      }

      let res = await axios.post(
        `/api/admin/pages/${arg?.id}/sections${
          arg?.targetId !== null ? "/" + arg?.targetId : ""
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );
      // `/api/admin/pages/${arg?.id}/sections`,{...arg});

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// // Sample ingredients array
// const ingredients = [
//   { status: true, file: '[object FileList]', name: 'file-1' },
//   { file: '[object FileList]', name: 'file-2' }
// ];

// // Create a new FormData object
// const formData = new FormData();

// // Map through the ingredients array
// ingredients.forEach((ingredient, index) => {
//   if (ingredient.file && ingredient.file !== '[object FileList]') {

//     formData.append(`file-${index + 1}`, ingredient.file);
//   }

//   formData.append(`status-${index + 1}`, ingredient.status ? 'true' : 'false');
//   // Adding the name property:
//   formData.append(`name-${index + 1}`, ingredient.name);
// });
