import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AddTextSectionThunk = createAsyncThunk(
  "section/AddTextSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {

      //api/admin/pages/4/sections
      let res = await axios.post(
        `/api/admin/pages/${arg?.id}/sections${
          arg?.targetId!==null ? "/" + arg?.targetId : ""
        }`,
        { ...arg }
      );
        // `/api/admin/pages/${arg?.id}/sections`,{...arg});

      return res.data;
    }
     catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
