import React from "react";

import ReactDOM from "react-dom/client";
import "./API/axios-global";

// import css styles file
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

// app context provider
import ContextProvider from "./context/ContextProvider";

// create browser router from react router dom
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// import login page and dashboard layout page
import { Home, RootLayout, WebsiteHomePage } from "./pages/dashboardContent";
import { Login } from "./pages/login";
// -----------------------------------------------------------------------------

// Import Customer Service Components
import { CustomerService } from "./pages/dashboardContent/customerService";

// Import Blog Components
import { Blog, CreateNewBlog } from "./pages/dashboardContent/blog";

// Import  EditMyAccount Components
import { EditMyAccount } from "./pages/dashboardContent/editMyAccount";

// Import SettingMyAccount Components
import { SettingMyAccount } from "./pages/dashboardContent/settingMyAccount";

// Import ProgramTraining Components
import { Provider } from "react-redux";
import AxiosInterceptors from "./API/AxiosInterceptors";
import { Store } from "./RTK/Store";
import { ContactInfo } from "./pages/contactInfo";
import HomeMainSection from "./pages/dashboardContent/HomeMainSection/HomeMainSection";
import HomeNewsSection from "./pages/dashboardContent/HomeNewsSection/HomeNewsSection";
import HomeSectionAchievements from "./pages/dashboardContent/HomeSectionAchievements/HomeSectionAchievements";
import HomeSectionImages from "./pages/dashboardContent/HomeSectionImages/HomeSectionImages";
import HomeSectionNew from "./pages/dashboardContent/HomeSectionNew/HomeSectionNew";
import HomeSectionPartners from "./pages/dashboardContent/HomeSectionPartners/HomeSectionPartners";
import HomeSectionServices from "./pages/dashboardContent/HomeSectionServices/HomeSectionServices";
import HomeSectionTestimonials from "./pages/dashboardContent/HomeSectionTestimonials/HomeSectionTestimonials";
import HomeSectionVideoAlbums from "./pages/dashboardContent/HomeSectionVideoAlbums/HomeSectionVideoAlbums";
import HomeWhoAreWeSection from "./pages/dashboardContent/HomeWhoAreWeSection/HomeWhoAreWeSection";
import MainSectionsBox from "./pages/dashboardContent/blog/MainSectionsBox/MainSectionsBox";
import Orders from "./pages/dashboardContent/orders/Orders";

// ---------------------------------------------------------------------------

// The app Routes
const router = createBrowserRouter([
	/* login page */
	{
		path: "login",
		element: <Login />,
	},

	/** rootLayout page */
	{
		path: "/",
		element: (
			<AxiosInterceptors>
				<RootLayout />
			</AxiosInterceptors>
		),
		children: [
			// THS IS THE HOME PAGE OF DASHBOARD
			{
				index: true,
				element: <Home />,
			},
			/**--------------------------------------------------------------------------- */

			/** WebSite Home Page */
			{ path: "website-home-page", element: <WebsiteHomePage /> },
			{ path: "website-home-page-change/:name", element: <HomeSectionNew /> },
			{
				path: "website-home-page-change/main-section/:name",
				element: <HomeMainSection />,
			},
			{
				path: "website-home-page-change/who-are-we/:name",
				element: <HomeWhoAreWeSection />,
			},
			{
				path: "website-home-page-change/news/:name",
				element: <HomeNewsSection />,
			},
			{
				path: "website-home-page-change/electronic-services/:name",
				element: <HomeSectionServices />,
			},
			{
				path: "website-home-page-change/achievements/:name",
				element: <HomeSectionAchievements />,
			},
			{
				path: "website-home-page-change/testimonials/:name",
				element: <HomeSectionTestimonials />,
			},
			{
				path: "website-home-page-change/partners/:name",
				element: <HomeSectionPartners />,
			},
			{
				path: "website-home-page-change/images/:name",
				element: <HomeSectionImages />,
			},
			{
				path: "website-home-page-change/video-albums/:name",
				element: <HomeSectionVideoAlbums />,
			},

			/** customer service Page */
			{ path: "customer-service", element: <CustomerService /> },

			/** orders */
			{ path: "orders", element: <Orders /> },
			/** contact info Page */

			{ path: "contact-info", element: <ContactInfo /> },

			/* ---------------------------------------------------------
			 * Blog Components
			 * --------------- */
			{ path: "create-page", element: <Blog /> },

			/** nested Create New Blog Page */
			{
				path: "create-page/create-new-article",
				element: <CreateNewBlog />,
			},
			{
				path: "create-page/create-new-main-section/:sectionId",
				element: <MainSectionsBox />,
			},
			{
				path: "create-page/create-new-article/edit/:pageId",
				element: <CreateNewBlog />,
			},

			/* Edit My Account Page */
			{ path: "edit-my-account", element: <EditMyAccount /> },

			/* Setting My Account Page */
			{ path: "setting-my-account", element: <SettingMyAccount /> },
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={Store}>
		<ContextProvider>
			<RouterProvider router={router} />
		</ContextProvider>
	</Provider>
);
