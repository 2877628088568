import { createSlice } from "@reduxjs/toolkit";
import { LoginThunk } from "../Thunk/LoginThunk";

let initState = {
	code: null,
	token: null,
	mode: "light",
};

let LoginReducer = createSlice({
	name: "login",

	initialState: initState,
	reducers: {
		changeTheme: (state, action) => {
			state.mode = action.payload.mode;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(LoginThunk.pending, (state, action) => {})
			.addCase(LoginThunk.fulfilled, (state, action) => {
				state.code = action.payload.code;

				state.token = action.payload.data.token;
				localStorage.setItem("token", action.payload.data.token);
				localStorage.setItem("avatar", action.payload.data.avatar);
				localStorage.setItem("user_name", action.payload.data.name);
				localStorage.setItem("logo_gc", action.payload.data.siteLogo);
				localStorage.setItem("loggedUserId", action.payload.data.id);
				localStorage.setItem(
					"permissions",
					JSON.stringify(action.payload.data.permissions)
				);
			})
			.addCase(LoginThunk.rejected, (state, action) => {
				state.code = action.payload.code;
				localStorage.setItem("token", "");
			});
	},
});

export default LoginReducer.reducer;

export let { changeTheme } = LoginReducer.actions;
