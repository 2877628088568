import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let GetMyAccountDataThunk = createAsyncThunk(
	"myAccount/GetMyAccountData",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`/auth/profile`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
