import { createSlice } from "@reduxjs/toolkit";
import {
	ConfirmOrderThunk,
	FinishOrderThunk,
	OrdersThunk,
} from "../Thunk/OrdersThunk";

let initState = {
	reload: true,
	orders: [],
	currentPage: 1,
	lastPage: 1,
};

let OrdersReducer = createSlice({
	name: "Orders",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======contact===========
			.addCase(OrdersThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(OrdersThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.orders = action.payload?.data;
				state.currentPage = action.payload?.meta?.current_page;
				state.lastPage = action.payload?.meta?.last_page;
			})
			.addCase(OrdersThunk.rejected, (state, action) => {
				state.reload = false;
			})
			.addCase(ConfirmOrderThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ConfirmOrderThunk.fulfilled, (state, action) => {
				state.reload = false;
			})
			.addCase(ConfirmOrderThunk.rejected, (state, action) => {
				state.reload = false;
			})
			.addCase(FinishOrderThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(FinishOrderThunk.fulfilled, (state, action) => {
				state.reload = false;
			})
			.addCase(FinishOrderThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});

export default OrdersReducer.reducer;
