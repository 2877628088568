import React from "react";
import { useNavigate } from "react-router-dom";

// import css styles
import "../../index.css";

//import dashboard Pages
import { dashboardPages } from "../../data/dashboardRoutes";

const DashboardRoutesWeights = () => {
	const navigate = useNavigate();

	// to drawing the UI
	const dashboardPagesRouts = dashboardPages.map((page) => (
		<div
			key={page?.id}
			className=' w-40 h-[141px] transition hover:bg-unnamed_color_055345 bg-unnamed_color_119184 dashboard-routes-wights-style cursor-pointer'>
			<div
				className=' h-full flex flex-col justify-center items-center gap-3'
				onClick={() => navigate(`${page?.navigateTo}`)}>
				<div className=''>{page?.icon}</div>
				<div className='text-center text-xl text-white font-Tajawal font-normal'>
					{page?.pageTitle}
				</div>
			</div>
		</div>
	));

	return (
		<div className='m-auto dashboard-routes-wights-width flex flex-wrap justify-start items-center gap-4 py-10'>
			{dashboardPagesRouts}
		</div>
	);
};

export default DashboardRoutesWeights;
