// import { Select } from 'antd';
// import React from 'react';
// const options = [];
// for (let i = 10; i < 36; i++) {
//   options.push({
//     value: i.toString(36) + i,
//     label: i.toString(36) + i,
//   });
// }
// const handleChange = (value) => {
//   console.log(`selected ${value}`);
// };
// const SelectSearchBox = () => (
//   <Select
//     mode="tags"
//     style={{
//       width: '100%',
//     }}
//     placeholder="Tags Mode"
//     onChange={handleChange}
//     options={options}
//   />
// );
// export default SelectSearchBox;
import { Empty, Form, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import React from 'react';
import { useController } from 'react-hook-form';

const SelectSearchBox = ({
  name,
  label,
  validateStatus,
  defaultValue,
  setValue,
  control,
  errorMsg,
  value,
  dataSearch,
  placeholder,
  onSelect
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });
  // console.log(control)

  const hanldeClear = () => {
    setValue(name, '');
  };
  return (
    <Form.Item label={label} help={errorMsg} className=' min-w-[250px]  ' validateStatus={validateStatus}>
      <Select
        {...field}
        // value={value(name)}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto'
        }}
        className="min-h-[50px] hover:"
        notFoundContent={<Empty description={false}>لا يوجد بيانات متاحة</Empty>}
        // onSelect={(date) => {
        //   console.log(date)
        //   setValue(name, date);
        // }}
        // onChange={(date) => {
        //   setValue(name, date);
        // }}
        placeholder={placeholder}
        onClear={hanldeClear}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch={true}
        onSelect={onSelect}>
        {dataSearch?.length > 0 &&
          dataSearch.map((op, index) => (
            <Option key={index} value={String(op.id)}>
              {op.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};
export default SelectSearchBox;
