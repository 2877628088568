import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let GetSiteLogoThunk = createAsyncThunk(
	"myAccount/GetSiteLogo",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`/api/admin/site_logo`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
