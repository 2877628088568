import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let EditMyAccountThunk = createAsyncThunk(
	"myAccount/EditMyAccount",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let formData = new FormData();
			formData.append("name", arg?.name);
			formData.append("email", arg?.email);
			if (arg?.avatar !== undefined && arg.avatar !== ""){
			console.log(arg?.avatar )

			formData.append("avatar", arg?.avatar);

			}


			let res = await axios.post(`/auth/profile`, formData);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
