import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let UpdateSocialLinksThunk = createAsyncThunk(
	"socialLinks/UpdateSocialLinks",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.post(`/api/social_links`, {
				uri: arg,
			});

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
