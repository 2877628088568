import * as React from "react";
import { useContext, useState, useEffect } from "react";
import Context from "../../../context/Context";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// css styles
import { InfoOutlined } from "@mui/icons-material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/system";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { OrdersThunk } from "../../../RTK/Thunk/OrdersThunk";

import EmptyBox from "../../../components/EmptyBox/EmtyBox";
import PaginationBox from "../../../components/PaginationBox/PaginationBox";
import { Loader } from "../../../components/loader";

import "../../../index.css";
import OrdersActions from "./OrdersActions";

// -----------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#C2D2EA",
		color: "#000",
		fontSize: 18,
		fontWeight: 500,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
		padding: "10px",
	},
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme?.palette?.action?.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const OrdersTable = ({
	pageTarget,
	rowsCount,
	setRowsCount,
	setPageTarget,
}) => {
	let dispatch = useDispatch();

	const [filter, setFilter] = useState("");

	let { orders, lastPage, reload, currentPage } = useSelector(
		(state) => state.OrdersReducer
	);

	// get contact data
	useEffect(() => {
		dispatch(OrdersThunk({ page: pageTarget, number: rowsCount }));
	}, [pageTarget, rowsCount]);

	// this context to open and close customer service info modal
	const customerServiceModalContext = useContext(Context);
	const { setOpenCustomerServiceModal, setCustomerServiceInfo } =
		customerServiceModalContext;
	// ------------------------------------------------------

	/**  handle open contact us info modal  */
	const handleOpenCustomerServiceModal = (info) => {
		setOpenCustomerServiceModal(true);
		setCustomerServiceInfo(info);
	};

	const getStatusText = (status) => {
		switch (status) {
			case 1:
				return (
					<div className=' border-[1px] border-solid border-orange-400 bg-orange-100  text-orange-400  w-32  m-auto py-1 rounded-xl flex justify-center items-center '>
						قيد المراجعة
					</div>
				);
			case 2:
				return (
					<div className=' border-[1px] border-solid border-blue-400 bg-blue-100  text-blue-400 w-32  m-auto py-1 rounded-xl flex justify-center items-center'>
						تمت الموافقة
					</div>
				);
			case 3:
				return (
					<div className='border-[1px] border-solid border-rose-400  bg-rose-100 text-rose-700 w-32   m-auto py-1  rounded-xl flex justify-center items-center '>
						مرفوض
					</div>
				);
			case 4:
				return (
					<div className='border-[1px] border-solid border-green-400 bg-green-100  text-green-400 w-32  m-auto py-1  rounded-xl flex justify-center items-center '>
						مكتمل
					</div>
				);
			case 5:
				return (
					<div className=' border-[1px] border-solid border-gray-400  bg-gray-100  text-gray-400 w-32  m-auto py-1 rounded-xl flex justify-center items-center '>
						ملغي
					</div>
				);

			default:
				return (
					<div className='border-[1px] border-solid border-orange-400 bg-orange-100  text-orange-400 w-32  m-auto py-1  rounded-xl flex justify-center items-center '>
						غير معروف
					</div>
				);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					mb: 2,
					boxShadow: "none",
					borderRadius: "16px",
					padding: "30px 25px",
				}}>
				{/* toolbar */}
				<OrdersActions orders={orders} filter={filter} setFilter={setFilter} />
				{reload ? (
					<Loader />
				) : orders.length ? (
					<>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 800 }} aria-label='customized table'>
								<TableHead>
									<TableRow>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											م
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											اسم العميل
										</StyledTableCell>

										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											رقم الجوال
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											حالة الطلب
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											الإجراءات
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{orders
										.filter(
											(row) =>
												row.name.toLowerCase().includes(filter.toLowerCase()) ||
												row.phone.toLowerCase().includes(filter.toLowerCase())
										)
										.map((row, index) => (
											<StyledTableRow key={row.id}>
												<StyledTableCell align='center'>
													{index + 1}
												</StyledTableCell>
												<StyledTableCell align='center'>
													{row.name}
												</StyledTableCell>

												<StyledTableCell
													align='center'
													style={{ direction: "ltr" }}>
													{row.phone}
												</StyledTableCell>
												<StyledTableCell align='center'>
													{getStatusText(row.status)}
												</StyledTableCell>
												<StyledTableCell align='center'>
													<div className='action flex items-center justify-center gap-2'>
														<IconButton
															aria-label=''
															onClick={() => {
																handleOpenCustomerServiceModal(row);
															}}>
															<InfoOutlined className='text-[#283078]' />
														</IconButton>
													</div>
												</StyledTableCell>
											</StyledTableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>

						{orders?.length !== 0 && (
							<PaginationBox
								pageCount={lastPage}
								rowsCount={rowsCount}
								currentPage={currentPage}
								setPageTarget={setPageTarget}
								setRowsCount={setRowsCount}
							/>
						)}
					</>
				) : (
					<EmptyBox />
				)}
			</Paper>
		</Box>
	);
};

export default OrdersTable;
