import React, { useState } from "react";
import { BlogIcon } from "../../../assets/icons";
import {
	SectionLabel,
	UploadProgramAdvantageItemIcon,
} from "../../../components";

const PageTitle = ({register,iconName,errors, inputName,setValue,
		watch,}) => {
	const [createBlock, setCreateBlock] = useState([
		{
			// title: "",
			// image: null,
			// previewImgOrVideo: null,
			// link: "",
			points: [{ icon: null, point: "", description: "" }],
		},
	]);
	// console.log(createBlock);

	const handleIconChange = (selectedIcon, blockIndex, pointIndex) => {
		const updatedBlocks = [...createBlock];
		updatedBlocks[blockIndex].points[pointIndex].icon = selectedIcon;
		setCreateBlock(updatedBlocks);
	};

	const programAdvantageBlock = createBlock?.map((block, blockIndex) => (
		<section className='mb-6'>
			<SectionLabel
				label={" قم إضافة اسم الصفحه و إضافة الايقونة الخاص بها"}
				icon={<BlogIcon className='programBlogIcon' />}
			/>
			{/* Create item input */}

			{block?.points?.map((point, pointIndex) => (
				<div key={pointIndex}>
					<div className='w-full flex flex-col justify-start items-start gap-[10px] mb-5'>
						<div className='w-full  max-w-[400px] h-[56px] border-[1px] border-[#E8E8E8] bg-[#ebebeb]  rounded flex justify-start items-center'>
							{/* Upload icon */}
							<div className=' w-fit h-full flex justify-center items-center rounded-r-[5px] bg-unnamed_color_C6C6C6'>
								<UploadProgramAdvantageItemIcon
									setValue={	setValue}  watch={watch}  iconName={iconName}

									icon={watch(iconName)}

									setIcon={(selectedIcon) =>
										// handleIconChange(selectedIcon, blockIndex, pointIndex)
										setValue(iconName,selectedIcon)
									}
								/>
							</div>

							{/* Add info value to packages */}
							<input
								type='text'
															{...register(inputName)}

								placeholder={`اسم الصفحه`}
								className=' w-full  h-full tags_input placeholder:text-unnamed_color_C6C6C6   text-[16px] font-normal font-Tajawal text-black  border-[1px] border-solid border-[#D3D3D3] rounded-[5px] p-5'
							/>
						</div>
						<div className=" w-full  flex justify-start items-start gap-[6px] text-ured">
<span>
	{errors?.[inputName]?.message}
</span>
{

}
{(errors?.[iconName]?.message)&&(errors?.[inputName]?.message)?" و ":"" }


<span>
	{errors?.[iconName]?.message}
									{/* {(errors?.[iconName]?.type=="required"&&errors?.[iconName]?.message)?(errors?.[iconName]?.message):null} */}

</span>


						</div>
					</div>
				</div>
			))}

			{/* Add more item button */}
		</section>
	));
	return <>{programAdvantageBlock}</>;
};

export default PageTitle;
