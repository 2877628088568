import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

// import components
import { PagesBreadcrumbs, SectionLabel } from "../../../components";

//  import css styes

import "../../../index.css";

// icons

import { SlEye } from "react-icons/sl";

import {
	DeleteIcon,
	EyeIcon,
	ImagesIcon,
	LockIcon,
	PlusIcon,
	SaveIcon,
	UploadImagesIcon,
} from "../../../assets/icons";

import { Loader } from "../../../components/loader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ChangePasswordThunk } from "../../../RTK/Thunk/ChangePasswordThunk";
import { GetSiteLogoThunk } from "../../../RTK/Thunk/GetSiteLogoThunk";
import { UpdateSiteLogoThunk } from "../../../RTK/Thunk/UpdateSiteLogoThunk";
import { DummyImage } from "../../../assets/images";
import { GetSocialLinksThunk } from "../../../RTK/Thunk/GetSocialLinksThunk";
import { UpdateSocialLinksThunk } from "../../../RTK/Thunk/UpdateSocialLinksThunk";

// handle according
const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: "transparent",
	"&:not(:last-child)": {
		marginBottom: "0",
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme }) => ({
		width: "100%",
		backgroundColor: "#FFF",
		marginBottom: "0",
		flexDirection: "row",
		minHeight: "60px",
		border: "1px solid #EEEEEE",
		borderRadius: " 4px",

		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(-90deg)",
		},
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(1),
		},
	})
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	border: "none",
	padding: "35px 12px 25px 12px",
	backgroundColor: "#EEEEEE",
}));
// -------------------------------------------------------------------------------

// Change password
const ChangePasswordSection = () => {
	const dispatch = useDispatch();
	let { reload } = useSelector((state) => state.ChangePasswordReducer);

	const [old_password, setOld_password] = useState("");
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	// -----------------------------------------------------------------
	const [new_password, setNew_password] = useState("");
	const [showNewPassword, setShowNewPassword] = useState(false);

	// -----------------------------------------------------------------
	const [new_password_confirmation, setNew_password_confirmation] =
		useState("");
	const [showConformPassword, setShowConformPassword] = useState(false);
	// ======================================================================

	// handleSaveNewPassword
	const handleSaveNewPassword = () => {
		const payload = {
			old_password: old_password,
			new_password: new_password,
			new_password_confirmation: new_password_confirmation,
		};

		dispatch(ChangePasswordThunk(payload));
	};

	useEffect(() => {
		if (reload) {
			setOld_password("");
			setNew_password("");
			setNew_password_confirmation("");
		}
	}, [reload]);

	return reload ? (
		<div className='h-full w-full flex justify-center items-center '>
			<Loader />
		</div>
	) : (
		<>
			{/* current password */}
			<section className='mb-6 px-4'>
				<h6 className=' text-[#200407] text-[16px] font-normal mb-1 '>
					كلمة المرور الحالية
				</h6>
				<div className='w-full h-12 bg-[#FFFFFF] rounded dashboard-routes-wights-style flex justify-start items-center px-2'>
					<LockIcon className='blog-icon' />

					<input
						type={showCurrentPassword ? "text" : "password"}
						name='userName'
						value={old_password}
						onChange={(e) => setOld_password(e.target.value)}
						placeholder='••••••••'
						className={`w-full  bg-transparent focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal py-3 px-2 `}
					/>
					{showCurrentPassword ? (
						<SlEye
							className='cursor-pointer w-6 h-6 open-eye'
							onClick={() => setShowCurrentPassword(!showCurrentPassword)}
						/>
					) : (
						<EyeIcon
							className='cursor-pointer'
							onClick={() => setShowCurrentPassword(!showCurrentPassword)}
						/>
					)}
				</div>
			</section>
			{/* new password */}
			<section className='mb-6 px-4'>
				<h6 className=' text-[#200407] text-[16px] font-normal mb-1 '>
					كلمة المرور الجديدة
				</h6>
				<div className='w-full h-12 bg-[#FFFFFF] rounded dashboard-routes-wights-style flex justify-start items-center px-2'>
					<LockIcon className='blog-icon' />

					<input
						type={showNewPassword ? "text" : "password"}
						name='userName'
						value={new_password}
						onChange={(e) => setNew_password(e.target.value)}
						placeholder='••••••••'
						className={`w-full  bg-transparent focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal py-3 px-2 `}
					/>
					{showNewPassword ? (
						<SlEye
							className='cursor-pointer w-6 h-6 open-eye'
							onClick={() => setShowNewPassword(!showNewPassword)}
						/>
					) : (
						<EyeIcon
							className='cursor-pointer'
							onClick={() => setShowNewPassword(!showNewPassword)}
						/>
					)}
				</div>
			</section>
			{/* confirm password */}
			<section className='mb-10 px-4'>
				<h6 className=' text-[#200407] text-[16px] font-normal mb-1 '>
					تأكيد المرور الجديدة
				</h6>
				<div className='w-full h-12 bg-[#FFFFFF] rounded dashboard-routes-wights-style flex justify-start items-center px-2'>
					<LockIcon className='blog-icon' />

					<input
						type={showConformPassword ? "text" : "password"}
						name='userName'
						value={new_password_confirmation}
						onChange={(e) => setNew_password_confirmation(e.target.value)}
						placeholder='••••••••'
						className={`w-full  bg-transparent focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal py-3 px-2 `}
					/>
					{showConformPassword ? (
						<SlEye
							className='cursor-pointer w-6 h-6 open-eye'
							onClick={() => setShowConformPassword(!showConformPassword)}
						/>
					) : (
						<EyeIcon
							className='cursor-pointer'
							onClick={() => setShowConformPassword(!showConformPassword)}
						/>
					)}
				</div>

				{new_password !== "" &&
					new_password_confirmation !== "" &&
					new_password !== new_password_confirmation && (
						<div className='text-[14px] text-[red] font-normal'>
							كلمة المرور غير متطابقة!
						</div>
					)}
			</section>

			{/* Save password  Button  */}
			<section className='flex justify-start px-4'>
				<button
					disabled={
						old_password && new_password && new_password_confirmation
							? false
							: true
					}
					className='w-[154px] h-14  flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-[#283078] rounded '
					onClick={handleSaveNewPassword}>
					<span>
						<SaveIcon />
					</span>
					<span className='text-white text-lg font-medium '>حفظ</span>
				</button>
			</section>
		</>
	);
};

/** Create Social Link */
const SocialMediaAccountsSection = () => {
	const dispatch = useDispatch();
	const [createNewSocialLink, setCreateNewSocialLink] = useState([]);
	let { socialLinksArr, reload } = useSelector(
		(state) => state.SocialLinksReducer
	);

	useEffect(() => {
		if (reload) {
			dispatch(GetSocialLinksThunk());
		}
	}, [dispatch, reload]);

	useEffect(() => {
		if (socialLinksArr) {
			setCreateNewSocialLink([...socialLinksArr]);
		}
	}, [socialLinksArr]);

	//handle set item to package info input
	const handleSetItems = (e, index) => {
		const updatedBlocks = [...createNewSocialLink];
		updatedBlocks[index] = e.target.value;
		setCreateNewSocialLink(updatedBlocks);
	};
	//--------------------------------------------------------------------

	// handle Add NewI tem Program Advantage
	const handleAddNewItemProgramAdvantage = () => {
		const updatedBlocks = [...createNewSocialLink];
		updatedBlocks.push("");
		setCreateNewSocialLink(updatedBlocks);
	};
	// -------------------------------------------------------------------------------

	// handle delete Social Media Accounts
	const deleteSocialLink = (index) => {
		const socialLinksAfterDelete = createNewSocialLink.filter(
			(item, itemIdx) => index !== itemIdx
		);

		dispatch(UpdateSocialLinksThunk(socialLinksAfterDelete));
	};

	// ========================================================================

	/** handle edit current social links */
	const editSocialLinks = () => {
		dispatch(UpdateSocialLinksThunk(createNewSocialLink));
	};

	return reload ? (
		<div className='h-full w-full flex justify-center items-center '>
			<Loader />
		</div>
	) : (
		<>
			{/* Create item input */}
			{createNewSocialLink?.map((item, index) => (
				<div key={index + 1}>
					<div className='w-full flex justify-center items-center gap-5 mb-5'>
						<div className='w-[90%]  h-12 border-[1px] border-[#E8E8E8] bg-[#C2D2EA]  rounded flex justify-start items-center'>
							{/* Add info value to packages */}
							<input
								type='text'
								value={item}
								onChange={(e) => handleSetItems(e, index)}
								placeholder={`رابط الصفحة (مثال : https://google.com)`}
								style={{ direction: item ? "ltr" : "rtl" }}
								className='w-full  h-full px-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
							/>
						</div>

						{/* Delete and switch buttons */}
						<div className='flex justify-start items-center gap-4'>
							{/* Delete image */}
							<button
								onClick={() => deleteSocialLink(index)}
								className='cursor-pointer disabled:cursor-not-allowed'>
								<DeleteIcon className='w-6 h-6 ' title='حذف الصفحة' />
							</button>
						</div>
					</div>
				</div>
			))}
			{/* Add more item button */}

			<div className='w-full flex justify-center items-center gap-5 mb-5'>
				<button
					onClick={() =>
						handleAddNewItemProgramAdvantage(createNewSocialLink?.length + 1)
					}
					title='اضافه صفحة جديدة'
					className='w-[90%]  h-12 border-[2px]  border-dashed border-unnamed_color_119184  bg-[#C2D2EA]   rounded flex justify-center items-center cursor-pointer'>
					<PlusIcon className='add-icon' />
				</button>

				<div className='flex justify-start items-center gap-4 w-6'></div>
			</div>
			{/* Save password  Button  */}
			<section className='w-full flex justify-center items-center gap-5 '>
				<button
					onClick={editSocialLinks}
					className='w-[90%]  h-14  flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-[#30506Bff] rounded '>
					<span>
						<SaveIcon />
					</span>
					<span className='text-white text-lg font-medium '>حفظ</span>
				</button>

				<div className='flex justify-start items-center gap-4 w-6'></div>
			</section>
		</>
	);
};

//---------------------------------------------------------------------------------------------
const SettingMyAccount = () => {
	// change password according
	const [expanded, setExpanded] = React.useState("");
	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	// --------------------------------------------------------

	// social media accounts according
	const [expanded2, setExpanded2] = React.useState("");
	const handleChange2 = (panel2) => (event, newExpanded2) => {
		setExpanded2(newExpanded2 ? panel2 : false);
	};

	/** --------------------------- change website logo ---------------------------------------------- */
	const dispatch = useDispatch();
	const { logo, reload } = useSelector((state) => state.ChangeSiteLogoReducer);
	const [imagePreview, setImagePreview] = useState(DummyImage);
	const [websiteLogo, setWebsiteLogo] = useState(null);

	/** get contact data */
	useEffect(() => {
		if (reload) {
			dispatch(GetSiteLogoThunk());
		}
	}, [dispatch, reload]);

	useEffect(() => {
		if (logo) {
			setImagePreview(logo);
		}
	}, [logo]);

	//1- to hide input type file
	const fileInputRef = React.createRef();

	// 2- Trigger a click event on the hidden file input when the button is clicked
	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	// to handle upload images files
	const handleUploadImages = (event) => {
		const file = event.target.files[0];
		setWebsiteLogo(file);

		// Create a temporary URL for the selected file and set it as the imagePreview state
		const imageUrl = URL.createObjectURL(file);
		setImagePreview(imageUrl);
	};

	// to handle delete images files
	const handleDeleteImages = () => {
		setWebsiteLogo(null);

		fileInputRef.current.value = null;
	};

	/** to send website to server  */
	const uploadImageToServer = () => {
		dispatch(UpdateSiteLogoThunk(websiteLogo));
	};

	useEffect(() => {
		if (reload) {
			handleDeleteImages();
		}
	}, [reload]);

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | الإعدادات</title>
			</Helmet>

			<div className='fixed top-[70px] left-0 z-50 w-full h-full min-h-ful overflow-y-auto pb-20 pt-5 hidden_body_scroll  bg-[#f6f6f6] '>
				<div className='w-[60%] m-auto py-4 '>
					{/* Pages Bread crumbs */}
					<section>
						<PagesBreadcrumbs
							currentPage={"الإعدادات"}
							parentPage={"الرئيسية"}
							isNested={true}
							route={"/"}
						/>
					</section>

					{/* upload website logo  */}
					<section className='mb-6 px-4'>
						<SectionLabel label={"شعار الموقع"} icon={<ImagesIcon />} />

						{/* upload images Component*/}

						<div className='p-2 w-1/2 h-[290px] overflow-hidden rounded-md border-[1px] border-[#ddd] mx-auto mb-2 flex justify-center items-center'>
							{reload ? (
								<div className='h-full w-full flex justify-center items-center '>
									<Loader />
								</div>
							) : imagePreview ? (
								<img src={imagePreview} alt='' className='rounded-md' />
							) : (
								<img src={websiteLogo} alt='' className='rounded-md  ' />
							)}
						</div>

						<div className={`w-full flex justify-start items-center gap-4`}>
							{/* upload images Component*/}
							<div className='w-full min-h-[100px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow p-4'>
								<div className='w-full h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
									<input
										type='file'
										accept='image/*'
										onChange={handleUploadImages}
										ref={fileInputRef}
										/* hidden this input and use icon below to upload  */
										style={{ display: "none" }}
									/>

									{/* Button to trigger file input */}
									<button
										onClick={handleButtonClick}
										className='w-full h-full flex justify-center items-center'>
										<UploadImagesIcon className='w-6 h-6' title='تغيير صورة ' />
									</button>
								</div>

								{/* Image Size */}
								<div className=''>
									<span className='text-[16px] text-[#C6C6C6] font-normal '>
										المقاس المقترح: 80 * 146
									</span>
								</div>
							</div>

							{/* Save and delete images*/}
							<div className='flex justify-start items-center gap-4'>
								{/* Save image */}

								<button
									onClick={uploadImageToServer}
									disabled={!websiteLogo}
									className='cursor-pointer disabled:cursor-not-allowed'>
									<SaveIcon className='w-6 h-6 save-icon' title='حفظ الصوره' />
								</button>
							</div>
						</div>
					</section>

					{/* Change password */}
					<section className='w-full '>
						<section className='mb-6 px-4'>
							<Accordion
								expanded={expanded === "panel1"}
								onChange={handleChange("panel1")}>
								<AccordionSummary
									aria-controls='panel1d-content'
									id='panel1d-header'
									expandIcon={
										<ArrowForwardIosSharpIcon
											sx={{
												fontSize: "1rem",
												color: "#000",
												transform: "rotate(180deg)",
											}}
										/>
									}>
									<Typography
										sx={{
											fontSize: "16px",
											fontWeight: "500",
											fontFamily: "Tajawal",
											color: "#000",
										}}>
										تغيير كلمة المرور
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{/* Change Password Section UI Component */}
									<ChangePasswordSection />
								</AccordionDetails>
							</Accordion>
						</section>
					</section>

					{/* Social Media Accounts  */}
					<section className='w-full '>
						<section className='mb-6 px-4'>
							<Accordion
								expanded={expanded2 === "panel2"}
								onChange={handleChange2("panel2")}>
								<AccordionSummary
									aria-controls='panel1d-content'
									id='panel1d-header'
									expandIcon={
										<ArrowForwardIosSharpIcon
											sx={{
												fontSize: "1rem",
												color: "#000",
												transform: "rotate(180deg)",
											}}
										/>
									}>
									<Typography
										sx={{
											fontSize: "16px",
											fontWeight: "500",
											fontFamily: "Tajawal",
											color: "#000",
										}}>
										صفحات التواصل الإجتماعي
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{/* Change Password Section UI Component */}
									<SocialMediaAccountsSection />
								</AccordionDetails>
							</Accordion>
						</section>
					</section>
				</div>
			</div>
		</>
	);
};

export default SettingMyAccount;
