import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

// import components
import { SectionLabel } from "../../../components";

// react-beautiful-dnd
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// import css styles
import "../../../index.css";

// Icons
import { BlogIcon, PlusIcon } from "../../../assets/icons";
import AlertDialog from "../../../components/AlertBox/AlertBox";

// Components
import { DeleteForever, ModeEdit, Widgets } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllPagesThunk } from "../../../RTK/Thunk/AllPagesThunk";
import { ChangeStatusThunk } from "../../../RTK/Thunk/ChangeStatusThunk";
import { DeletePageThunk } from "../../../RTK/Thunk/DeletePageThunk";
import EmptyBox from "../../../components/EmptyBox/EmtyBox";
import PaginationBox from "../../../components/PaginationBox/PaginationBox";
import SwitchBox from "../../../components/SwitchBox/SwitchBox";
import { Loader } from "../../../components/loader";

import { handlePriorityOfPageThunk } from "../../../RTK/Thunk/handlePriorityOfPageThunk";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#C2D2EA",
		color: "#000",
		fontSize: 18,
		fontWeight: 500,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
		padding: "10px",
	},
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme?.palette?.action?.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const Blog = () => {
	// Fetch data from API

	let navigate = useNavigate();
	let dispatch = useDispatch();
	let { pagesData, reload } = useSelector((state) => state.PageReducer);
	const [pagesArray, setPagesArray] = useState([]);
	useEffect(() => {
		if (pagesData) {
			setPagesArray(pagesData);
		}
	}, [pagesData]);

	const currentPagesDataRef = React.useRef(true);
	useEffect(() => {
		if (currentPagesDataRef.current) {
			dispatch(AllPagesThunk());
		}
	}, [currentPagesDataRef]);

	// =================delete-row======================
	const [deleteId, setDeleteId] = React.useState(0);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [lastPage, setLastPage] = React.useState(1);
	const [pageTarget, setPageTarget] = useState(1);
	const [rowsCount, setRowsCount] = useState(10);

	const handleDeleteSection = (id) => {
		dispatch(
			DeletePageThunk({
				id: id,
			})
		)
			.unwrap()
			.then((data) => {
				dispatch(AllPagesThunk());
			})
			.catch((error) => {});
	};

	/* Handle drag and drop sorting pages items */
	const handleDragAndDrop = (result) => {
		const { source, destination, type } = result;

		// if the destination is not change
		if (!destination) {
			return;
		}

		if (
			source?.droppableId === destination?.droppableId &&
			source?.index === destination?.index
		) {
			return;
		}

		if (type === "pagesGroup") {
			const reorderedPages = [...pagesArray];
			const sourceIndex = source?.index;
			const destinationIndex = destination?.index;

			const [removedPage] = reorderedPages?.splice(sourceIndex, 1);
			reorderedPages?.splice(destinationIndex, 0, removedPage);

			// Update priority values based on the new order
			const requestData = reorderedPages?.map((page, index) => ({
				id: page?.id,
				priority: index,
			}));

			setPagesArray(reorderedPages);

			dispatch(handlePriorityOfPageThunk(requestData));
		}
	};

	const pagesContent = pagesArray?.map((row, index) => (
		<Draggable draggableId={row.id.toString()} key={row?.id} index={index}>
			{(provided) => (
				<>
					<StyledTableRow
						{...provided?.dragHandleProps}
						{...provided?.draggableProps}
						ref={provided?.innerRef}>
						<StyledTableCell align='center'>{index + 1}</StyledTableCell>
						<StyledTableCell align='center' className='text-black !text-center'>
							{row.name}
						</StyledTableCell>

						<StyledTableCell align='center'>{row.footer}</StyledTableCell>
						<StyledTableCell align='center'>{row.created_at}</StyledTableCell>

						<StyledTableCell align='center'>
							<div
								data-name={row.status}
								onClick={(e) => {
									if (e.target.tagName == "INPUT") {
										if (e.currentTarget.dataset.name == "true") {
											dispatch(
												ChangeStatusThunk({
													id: row.id,
													type: "page",
													active: false,
												})
											);
											e.currentTarget.dataset.name = false;
										} else {
											dispatch(
												ChangeStatusThunk({
													id: row.id,
													type: "page",

													active: true,
												})
											);
											e.currentTarget.dataset.name = true;
										}
									}
								}}>
								<SwitchBox status={row.status} />
							</div>
						</StyledTableCell>
						<StyledTableCell align='center'>
							<div className='action flex items-center justify-center gap-2'>
								<IconButton
									aria-label=''
									onClick={() => {
										navigate(`/create-page/create-new-main-section/${row?.id}`);
									}}>
									<Widgets className='text-unnamed_color_055345' />
								</IconButton>
							</div>
						</StyledTableCell>
						<StyledTableCell align='center'>
							<div className='action flex items-center justify-center gap-2'>
								<IconButton
									aria-label=''
									onClick={() => {
										navigate(`/create-page/create-new-article/edit/${row.id}`);
									}}>
									<ModeEdit />
								</IconButton>

								<IconButton
									aria-label=''
									onClick={() => {
										setOpenAlert(true);
										setDeleteId(row.id);
									}}>
									<DeleteForever className='text-[red]' />
								</IconButton>
							</div>
						</StyledTableCell>
					</StyledTableRow>

					{provided?.placeholder}
				</>
			)}
		</Draggable>
	));

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | انشاء صفحة جديدة</title>
			</Helmet>
			<div className='py-4 px-14'>
				<section className='mb-5 flex justify-between  items-center '>
					<SectionLabel
						label={"الصفحات الحالية"}
						icon={<BlogIcon className='blog_icon_inBlog_page' />}
					/>
					<button
						onClick={() => navigate("create-new-article")}
						className='w-[179px] h-11 flex justify-center items-center gap-2 bg-[#283078] rounded-lg cursor-pointer '>
						<PlusIcon className='w-6 create-blg-icon' />
						<span className=' text-[16px]  text-[#FFFFFF] font-medium '>
							انشاء صفحة جديدة
						</span>
					</button>
				</section>

				{reload ? (
					<Loader />
				) : pagesData.length ? (
					<>
						<TableContainer component={Paper} className='h-fit'>
							<Table>
								<TableHead>
									<TableRow>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											الرقم
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											الاسم
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											footer
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											تاريخ الانشاء
										</StyledTableCell>

										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											الحالة
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											أقسام
										</StyledTableCell>
										<StyledTableCell
											align='center'
											className='!bg-primaryBg capitalize'>
											أجراءات
										</StyledTableCell>
									</TableRow>
								</TableHead>

								<DragDropContext onDragEnd={handleDragAndDrop}>
									<Droppable droppableId='ROOT' type='pagesGroup'>
										{(provided) => (
											<TableBody
												{...provided?.droppableProps}
												ref={provided?.innerRef}>
												{pagesContent}
												{provided?.placeholder}
											</TableBody>
										)}
									</Droppable>
								</DragDropContext>
							</Table>
						</TableContainer>
						{pagesData?.length !== 0 && (
							<PaginationBox
								pageCount={lastPage}
								rowsCount={rowsCount}
								currentPage={currentPage}
								setPageTarget={setPageTarget}
								setRowsCount={setRowsCount}
							/>
						)}
					</>
				) : (
					<EmptyBox />
				)}
			</div>

			<AlertDialog
				open={openAlert}
				setOpen={setOpenAlert}
				handleDelete={handleDeleteSection}
				deleteId={deleteId}
				setDeleteId={setDeleteId}
			/>
		</>
	);
};

export default Blog;
