import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let ContactThunk = createAsyncThunk(
	"contact/ContactThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;

		try {
			let url = `admin/contact_us?handle=${arg.search || ""}&page=${
				arg.page
			}&per_page=10`;
			let urlSearch = `admin/contact_us?handle=${arg.search || ""}&page=${
				arg.page
			}&per_page=10`;
			let res = await axios.get(arg.search !== "" ? urlSearch : url);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
