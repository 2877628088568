import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
	apiKey: "AIzaSyCsQRNRx-N0VwPrdEsTGqM5W8LLT2LQ0ms",
	authDomain: "nueawin-notifications.firebaseapp.com",
	projectId: "nueawin-notifications",
	storageBucket: "nueawin-notifications.appspot.com",
	messagingSenderId: "355950011872",
	appId: "1:355950011872:web:57dcc8d92a79ed5a3e7785",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("/firebase-messaging-sw.js")
		.then((registration) => {
			console.log("Service Worker registered with scope:", registration.scope);
		})
		.catch((err) => {
			console.log("Service Worker registration failed: ", err);
		});
}

export { firestore, messaging, getToken };
