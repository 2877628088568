import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AddMainSectionInHomeThunk = createAsyncThunk(
  "mainPage/AddMainSectionInHomeThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let formData = new FormData();
                        formData.append(`text`, arg?.text);
      if (arg?.id) {
        arg?.ingredients?.forEach((ingredient, index) => {
          // console.log(ingredient);
          if (typeof ingredient?.image !== "string") {
            formData.append(`image`, ingredient?.image);
          }
          if (typeof ingredient?.file !== "string") {
            formData.append(`file`, ingredient?.file);
          }


              if (typeof ingredient?.video !== "string") {
                console.log(typeof ingredient?.video);
                formData.append(`video`, ingredient?.video);
              }
          formData.append(`link`, ingredient?.link);
          formData.append(`title`, ingredient?.title);
           formData.append(`main_title`, ingredient?.main_title);
           formData.append(`sub_title`, ingredient?.sub_title);
           formData.append(`customer_name`, ingredient?.customer_name);
           formData.append(`customer_info`, ingredient?.customer_info);

        });

      } else {

        arg?.ingredients?.forEach((ingredient, index) => {
          // console.log(ingredient);
            if (typeof ingredient?.file !== "string") {
              formData.append(`file`, ingredient?.file);
            }
            if (typeof ingredient?.image !== "string") {
              formData.append(`image`, ingredient?.image);
            }
            if (typeof ingredient?.video !== "string") {
              console.log(typeof ingredient?.video)
              formData.append(`video`, ingredient?.video);
            }
          // title most be link
          formData.append(`link`, ingredient?.link);
                    formData.append(`title`, ingredient?.title);
                    formData.append(`main_title`, ingredient?.main_title);
                    formData.append(`sub_title`, ingredient?.sub_title);
                    formData.append(`customer_name`, ingredient?.customer_name);
                    formData.append(`customer_info`, ingredient?.customer_info);
        });
      }

      let res = await axios.post(
        `/api/admin/${arg?.type}${arg?.id ? "/" + arg?.id : ""}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );
      // `/api/admin/pages/${arg?.id}/sections`,{...arg});

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// // Sample ingredients array
// const ingredients = [
//   { status: true, image: '[object FileList]', link: 'image-1' },
//   { image: '[object FileList]', link: 'image-2' }
// ];

// // Create a new FormData object
// const formData = new FormData();

// // Map through the ingredients array
// ingredients.forEach((ingredient, index) => {
//   if (ingredient.image && ingredient.image !== '[object FileList]') {

//     formData.append(`image-${index + 1}`, ingredient.image);
//   }

//   formData.append(`status-${index + 1}`, ingredient.status ? 'true' : 'false');
//   // Adding the link property:
//   formData.append(`link-${index + 1}`, ingredient.link);
// });
