import React, { useEffect, useRef, useState } from "react";

// icon
import {
	BlogIcon,
	CloseIcon,
	DeleteIcon,
	PlusIcon,
	SaveIcon,
	TextIcon,
} from "../../assets/icons";

import { Switch } from "@mui/material";

import SectionLabel from "../sectionLabel/SectionLabel";
import styles from "./AddNewFile.module.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { AddFileSectionThunk } from "../../RTK/Thunk/AddFileSectionThunk";
import { AllSectionsPagThunk } from "../../RTK/Thunk/AllSectionsPagThunk";
import { setCurrentSectionDataState } from "../../RTK/Reducers/SectionsReducer";
import { OneSectionThunk } from "../../RTK/Thunk/OneSectionThunk";

const SwitchStyle = {
	width: "25px",
	height: "14px",
	padding: "0",
	"& .MuiButtonBase-root ": {
		color: "#fff",
		"&.Mui-checked": {
			color: "#fff",
		},

		"&:hover": {
			backgroundColor: "transparent",
		},

		"&.Mui-checked+.MuiSwitch-track": {
			backgroundColor: "#283078",
			opacity: "1",
		},
	},
	"& .MuiSwitch-switchBase ": {
		padding: "1px",
		"&.Mui-checked": {
			transform: "translateX(11px)",
		},
	},

	"& .MuiSwitch-thumb": {
		width: "12px",
		height: "12px",
		boxShadow: "none",
	},
};

const AddNewFile = ({ showModalAddSection, setShowModalAddSection }) => {
	let { currentSectionData } = useSelector((state) => state.SectionsReducer);
	let dispatch = useDispatch();
	let params = useParams();
	// from hooks
	const schema = Yup.object().shape({
		title: Yup.string().required("قم بإضافة اسم القسم"),
		ingredients: Yup.array()
			// test select options and radio buttons
			.of(
				Yup.object().shape({
					name: Yup.string().required("قم بإضافة اسم الملف"),
					file: Yup.mixed().optional("قم بإضافة الملف"),
					status: Yup.mixed().optional("قم بإضافة الحالة"),
				})
			),
	});
	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			title: "",
			ingredients: [
				{
					name: "",
					file: "",
					status: true,
				},
			],
		},
	});
	const {
		fields: ingredientsFields,
		append: appendIngredientField,
		remove: removeIngredientField,
	} = useFieldArray({
		control,
		name: "ingredients",
	});
	// console.log(errors?.ingredients);
	// get current data target updated
	useEffect(
		(e) => {
			if (showModalAddSection?.isEdit && showModalAddSection?.targetId) {
				dispatch(
					OneSectionThunk({
						id: params?.sectionId,
						targetId: showModalAddSection?.targetId,
					})
				);
			}
		},
		[showModalAddSection]
	);
	const currentSectionDataRef = useRef(true);
	useEffect(() => {
		if (currentSectionData !== null && currentSectionDataRef.current) {
			// console.log(currentSectionData)
			setValue("title", currentSectionData?.title);
			// set ingredients arr data
			removeIngredientField(0);
			currentSectionData?.details?.forEach((el, index) => {
				// console.log(`el${index + 1}`, el);
				// console.log(el?.name);
				appendIngredientField({
					name: el?.name,
					file: el?.file,
					status: el?.status,
					id: el?.id,
				});
			});

			currentSectionDataRef.current = false;
		}
	}, [currentSectionData]);
	useEffect(() => {
		return () => {
			dispatch(setCurrentSectionDataState());
		};
	}, []);
	// console.log("ingredients",watch(`ingredients`));
	// console.log(watch(`ingredients.${1}.file.${0}`));
	// console.log(watch(`ingredients.${0}.file`));
	// -------------------------------------------------------------------------------
	const [targetDeleteIdItem, setTargetDeleteIdItem] = useState([]);
	let handleDeleteItem = (index) => {
		if (showModalAddSection?.targetId) {
			// watch(`ingredients.${index}.id`);
			let itemId = [...targetDeleteIdItem];
			itemId.push(watch(`ingredients.${index}.id`));
			//  console.log(watch(`ingredients.${index}.id`));
			setTargetDeleteIdItem(itemId);
		}
		removeIngredientField(index);
	};
	const [showSaveBT, setShowSaveBT] = React.useState(true);

	const onSubmit = async (data) => {
		setShowSaveBT(false);

		dispatch(
			AddFileSectionThunk({
				//  ingredients: data,
				...data,
				targetDeleteIdItem,
				id: params?.sectionId,
				type: "files",
				targetId: showModalAddSection?.targetId
					? showModalAddSection?.targetId
					: null,
			})
		)
			.unwrap()
			.then((data) => {
				setShowSaveBT(true);

				dispatch(
					AllSectionsPagThunk({
						id: params?.sectionId,
						page: 1,
					})
				);
				setShowModalAddSection({ show: false, type: "" });
			})
			.catch((error) => {
				setShowSaveBT(true);

				if (error?.data) {
					if (error?.data["stored_files.title"]) {
						// console.log(error?.data["stored_files.link"]);
						setError(`ingredients.${0}.title`, {
							message: error?.data["stored_files.title"],
						});
					}
					watch(`ingredients`)?.forEach((el, index) => {
						if (error?.data[`stored_files.${index}.name`]) {
							// console.log(error?.data["stored_files.link"]);
							setError(`ingredients.${index}.name`, {
								message: error?.data[`stored_files.${index}.name`],
							});
						}
						if (error?.data[`stored_files.${index}.status`]) {
							// console.log(error?.data["stored_files.link"]);
							setError(`ingredients.${index}.status`, {
								message: error?.data[`stored_files.${index}.status`],
							});
						}
						if (error?.data[`stored_files.${index}.file`]) {
							// console.log(error?.data["stored_files.link"]);
							setError(`ingredients.${index}.file`, {
								message: error?.data[`stored_files.${index}.file`],
							});
						}
					});
					if (error?.data?.title) {
						setError("title", { message: error?.data?.title });
					}
				}

				// console.log(error);
				// handle error here
			});
	};

	const programAdvantageBlock = () => {
		return (
			ingredientsFields?.length > 0 && (
				<>
					{ingredientsFields.map((block, blockIndex) => {
						return (
							<div
								key={blockIndex}
								className=' bg-unnamed_color_ebebeb mb-[30px]'>
								<section className='py-[15px] px-[25px]' key={blockIndex}>
									<SectionLabel label={"اسم الملف"} icon={<TextIcon />} />
									<div className='w-[94%] min-h-[56px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow flex justify-start items-center '>
										<input
											type='text'
											{...register(`ingredients.${blockIndex}.name`)}
											className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
										/>
									</div>
									{errors?.ingredients &&
										errors?.ingredients[blockIndex]?.name && (
											<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
												<span>
													{errors?.ingredients[blockIndex]?.name?.message}
												</span>
											</div>
										)}
								</section>
								<div className='py-[15px] px-[25px] w-full flex justify-start items-center gap-5 '>
									<div className='w-full overflow-hidden relative h-[60px] border-[1px] border-[#E8E8E8] bg-[#C2D2EA]  rounded flex justify-start items-center'>
										{/* Add info value to packages */}
										{/* <input
                      id="file"
                      type="file"
                      {...register(`ingredients.${blockIndex}.file`)}
                      // onChange={(e) => {
                      //   setError(`ingredients[${blockIndex}].file`);
											// }}
                    /> */}
										{typeof watch(`ingredients.${blockIndex}.file`) ==
											"string" && (
											<div className=' absolute text-sm w-full h-full top-0 right-[124px] text-slate-500 bg-[#C2D2EA] flex justify-start items-center'>
												{watch(`ingredients.${blockIndex}.file`)}
											</div>
										)}
										<input
											type='file'
											class='block w-full text-sm text-slate-500
     									 file:mr-4 file:py-2 file:px-4
     									 file:rounded-full file:border-0
     									 file:text-sm file:font-semibold
     									 file:bg-unnamed_color_119184 file:text-unnamed_color_ebebeb'
											// name="file"
											// defaultValue={watch(`ingredients.${blockIndex}.file`)}
											// onChange={(e) => {
											//   setError(`ingredients[${blockIndex}].file`);
											//   setValue(
											//     `ingredients.${blockIndex}.file`,
											//     e.target.files[0]
											//   );
											// }}

											{...register(`ingredients.${blockIndex}.file`)}
										/>
									</div>

									{/* Delete and switch buttons */}
									<div className='flex justify-start items-center gap-4'>
										{/* Delete image */}
										<button
											type='button'
											onClick={() => handleDeleteItem(blockIndex)}
											// disabled={!point?.link}
											className='cursor-pointer disabled:cursor-not-allowed'>
											<DeleteIcon className='w-6 h-6 ' title='حذف الملف' />
										</button>

										{/* disable and enable switch */}
										<Switch
											title='تعطيل/تفعيل'
											sx={SwitchStyle}
											name='status'
											onChange={(e) => {
												//  e.target.value
												setValue(
													`ingredients.${blockIndex}.status`,
													e.target.checked
												);
											}}
											checked={watch(`ingredients.${blockIndex}.status`)}
										/>
									</div>
								</div>
								{errors?.ingredients &&
									errors?.ingredients[blockIndex]?.file && (
										<div className=' w-full  px-[25px]  flex justify-start pb-[20px] items-start gap-[6px] text-ured'>
											<span>
												{errors?.ingredients[blockIndex]?.file?.message}
											</span>
										</div>
									)}
							</div>
						);
					})}
				</>
			)
		);
	};
	return (
		<>
			<div className='  px-[30px]  '>
				{/* Backdrop */}
				<div
					className={`${styles.backdrop} z-20`}
					onClick={() => setShowModalAddSection({ show: false, type: "" })}
				/>
				<div
					className={`z-[5000] absolute  top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] max-w-[750px]   w-[90%] h-fit pb-[40px] bg-white rounded-[10px]`}>
					{/* title of modal */}
					<div
						className={`w-full h-11 ${
							showModalAddSection?.isEdit
								? "bg-[#30506Bff]"
								: "bg-unnamed_color_055345"
						}   flex justify-center items-center px-6 ${styles.modal_title}`}>
						<h4
							className={`text-[16px] ${
								showModalAddSection?.isEdit
									? "text-unnamed_color_055345"
									: "text-white"
							}  font-medium flex-1 text-center`}>
							{showModalAddSection?.isEdit ? `تعديل ملف ` : " اضافة  ملف"}
						</h4>

						<div
							className='cursor-pointer '
							onClick={() => setShowModalAddSection({ show: false, type: "" })}>
							<CloseIcon
								className={`${
									showModalAddSection?.isEdit
										? "close-icon-black"
										: "close-icon-white"
								}`}
							/>
						</div>
					</div>
					<form
						className=' w-full  max-h-[600px] overflow-y-auto h-fit  pb-[40px]'
						onSubmit={handleSubmit(onSubmit)}>
						<>
							<div className='py-[15px] px-[25px] mt-[20px] '>
								<>
									<div className='w-full border-[1px] rounded-[2px] border-unnamed_color_C6C6C6 h-14 flex justify-start items-center gap-3 p-3 bg-unnamed_color_f6f6f6 add-new-section-title-shadow '>
										<BlogIcon className='blog-icon' />
										<input
											className='w-full h-full bg-transparent placeholder:text-[#C6C6C6] text-lg font-normal text-black'
											type='text'
											placeholder='اسم القسم'
											{...register("title")}
										/>
									</div>
									<div className=' w-full py-[10px]  flex justify-start  items-start gap-[6px] text-ured'>
										<span>{errors?.title?.message}</span>
									</div>
								</>
							</div>

							{programAdvantageBlock()}
							<div className='!px-[25px] my-[30px]'>
								<button
									onClick={() => {
										appendIngredientField({
											name: "",
											file: null,
											status: true,
										});
									}}
									title='اضافه ملف جديدة'
									className='w-[100%]   h-12 border-[2px]  border-dashed border-unnamed_color_119184   bg-[#C2D2EA]   rounded flex justify-center items-center cursor-pointer'>
									<PlusIcon />
								</button>
							</div>
							{/* content of modal */}
							{showSaveBT ? (
								<button
									type='submit'
									className='w-[154px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '>
									<span>
										<SaveIcon />
									</span>
									<span className='text-white text-lg font-medium '>حفظ</span>
								</button>
							) : (
								<button
									type='button'
									className='w-[154px] !cursor-wait h-14 m-auto flex justify-center items-center gap-2 p-3  disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '>
									<span>
										<SaveIcon />
									</span>
									<span className='text-white text-lg font-medium '>حفظ</span>
								</button>
							)}
						</>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddNewFile;
