import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let handlePriorityOfPageThunk = createAsyncThunk(
	"pages/handlePriorityOfPageThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.post(`api/admin/pages/change_positions`, {
				positions: arg,
			});

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
