import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// components
import { PagesBreadcrumbs, SectionLabel } from "../../components";
import { SaveIcon, StarIcon } from "../../assets/icons";
import { Loader } from "../../components/loader";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetContactUsDataThunk } from "../../RTK/Thunk/GetContactUsDataThunk";
import { UpdateContactUsDataThunk } from "../../RTK/Thunk/UpdateContactUsDataThunk";
const ContactInfo = () => {
	const dispatch = useDispatch();
	let { contactUsData, reload } = useSelector(
		(state) => state.ContactUsSettingReducer
	);

	/** get contact data */
	useEffect(() => {
		if (reload) {
			dispatch(GetContactUsDataThunk());
		}
	}, [dispatch, reload]);
	const [contactUsValues, setContactUsValues] = useState({
		address: "",
		phone: "",
		email: "",
	});

	// handle onchange values
	const handleCustomerServicesValues = (e) => {
		const { name, value } = e.target;

		setContactUsValues((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	/** ste current profile data */
	useEffect(() => {
		if (contactUsData) {
			setContactUsValues({
				...contactUsValues,
				address: contactUsData?.address,
				phone: contactUsData?.phone,
				email: contactUsData?.email,
			});
		}
	}, [contactUsData]);

	const editContactUsInfo = () => {
		dispatch(UpdateContactUsDataThunk(contactUsValues));
	};

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | إعدادت تواصل معنا </title>
			</Helmet>

			{reload ? (
				<div className='h-full w-full flex justify-center items-center '>
					<Loader />
				</div>
			) : (
				<div className='py-4 px-14 w-[90%]'>
					{/* Pages Bread crumbs */}
					<section>
						<PagesBreadcrumbs
							currentPage={" إعدادت تواصل معنا"}
							parentPage={null}
							isNested={false}
						/>
					</section>

					{/* full name */}
					<section className='mb-6'>
						<SectionLabel label={"العنوان"} icon={<StarIcon />} />

						<input
							type='text'
							name='address'
							placeholder='مسجد المعتلاء بأُم الشبرم'
							value={contactUsValues?.address}
							onChange={handleCustomerServicesValues}
							className=' w-[90%] h-[56px] rounded-[10px] bg-[#C2D2EA] section-box-shadow focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal py-2 px-3 '
						/>
					</section>

					{/* Phone number */}
					<section className='mb-6'>
						<SectionLabel label={"رقم الجوال"} icon={<StarIcon />} />

						<div
							className={`w-[90%] h-[56px] flex justify-start items-center  rounded-[10px] bg-[#C2D2EA] section-box-shadow  pl-2`}>
							<input
								type='text'
								name='phone'
								value={contactUsValues?.phone}
								onChange={handleCustomerServicesValues}
								placeholder='05055606'
								style={{ direction: "ltr" }}
								className={` w-full h-full focus:outline-none placeholder:text-[#C6C6C6] bg-transparent  text-[16px] font-normal font-Tajawal py-2 pl-0 pr-3 `}
							/>

							{/* Country key */}
							<div className=''>966</div>
						</div>
					</section>

					{/* Email */}
					<section className='mb-6'>
						<SectionLabel label={"البريد الالكتروني"} icon={<StarIcon />} />

						<input
							type='email'
							name='email'
							placeholder='sample@gmail.com'
							value={contactUsValues?.email}
							style={{ direction: contactUsValues?.email ? "ltr" : "rtl" }}
							onChange={handleCustomerServicesValues}
							className=' w-[90%] h-[56px] rounded-[10px] bg-[#C2D2EA] section-box-shadow focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal py-2 px-3 '
						/>
					</section>

					{/* Save password  Button  */}
					<section className='flex justify-start px-4'>
						<button
							disabled={
								contactUsValues?.email === "" ||
								contactUsValues?.address === "" ||
								contactUsValues?.phone === ""
							}
							onClick={editContactUsInfo}
							className='w-[154px] h-14  flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-[#283078] rounded '>
							<span>
								<SaveIcon />
							</span>
							<span className='text-white text-lg font-medium '>حفظ</span>
						</button>
					</section>
				</div>
			)}
		</>
	);
};

export default ContactInfo;
