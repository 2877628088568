import React from "react";
import { FaDatabase } from "react-icons/fa6";

const EmptyBox = () => {
	return (
		<div className=' flex justify-center items-center  flex-col gap-[20px] h-[300px]'>
			<FaDatabase className='!text-gray-400 !text-[52px]' />
			<h4 className=' text-gray-500  !text-[18px] font-medium '>
				لم يتم العثور على البيانات!
			</h4>
		</div>
	);
};

export default EmptyBox;
