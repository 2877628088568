import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AllMainSectionDataThunk = createAsyncThunk(
  "mainPage/AllMainSectionDataThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let url = `api/admin/${arg?.type}?page=${arg?.page}&per_page=10`;

      let res = await axios.get(url);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
