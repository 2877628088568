import React from "react";

import * as XLSX from "xlsx";
import { Box, Button, TextField } from "@mui/material";
import { RiFileExcel2Line } from "react-icons/ri";

const textFieldStyle = {
	width: "60%",
	"& .MuiOutlinedInput-root": {
		height: "50px",

		"@media (max-width: 600px)": {
			height: "46px",
		},
	},
	"& .MuiOutlinedInput-notchedOutline": {
		textAlign: "right",
	},
	"& .MuiInputLabel-root": {
		left: "auto",
		right: "29px",
		transformOrigin: "top right",
	},
};

const OrdersActions = ({ orders, filter, setFilter }) => {
	/** handle export to excel */
	const handleExport = () => {
		const filteredData = orders.filter(
			(row) =>
				row.name.toLowerCase().includes(filter.toLowerCase()) ||
				row.email.toLowerCase().includes(filter.toLowerCase()) ||
				row.phone.toLowerCase().includes(filter.toLowerCase())
		);

		const worksheet = XLSX.utils.json_to_sheet(filteredData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
		XLSX.writeFile(workbook, "orders.xlsx");
	};

	return (
		<Box display='flex' justifyContent='space-between' gap={2} mb={2}>
			<Button
				sx={{ width: "200px", height: "50px" }}
				variant='contained'
				color='primary'
				onClick={handleExport}>
				<RiFileExcel2Line style={{ fontSize: "18px", marginLeft: "5px" }} />
				تصدير الطلبات
			</Button>

			<TextField
				sx={textFieldStyle}
				label='ابحث باسم العميل او رقم الجوال'
				variant='outlined'
				value={filter}
				onChange={(e) => setFilter(e.target.value)}
			/>
		</Box>
	);
};

export default OrdersActions;
