import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, TableBody } from "@mui/material";
import * as Yup from "yup";
import { ImagesIcon, TextIcon, UploadImagesIcon } from "../../../assets/icons";
import {
	PagesBreadcrumbs,
	PreviewVideoModal,
	SectionLabel,
} from "../../../components";
import PaginationBox from "../../../components/PaginationBox/PaginationBox";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";

// css styles

import { DeleteForever, Edit, ModeEdit } from "@mui/icons-material";
import { PlusIcon } from "evergreen-ui";
import {
	setCurrentSectionDataStateRow,
	setSectionDataState,
} from "../../../RTK/Reducers/HomePageReducer";
import { AddMainSectionInHomeThunk } from "../../../RTK/Thunk/AddMainSectionInHomeThunk";
import { AllMainSectionDataThunk } from "../../../RTK/Thunk/AllMainSectionDataThunk";
import { DeleteCurrentRowInMainSectionThunk } from "../../../RTK/Thunk/DeleteCurrentRowInMainSectionThunk";
import { OneMainPagesSectionThunk } from "../../../RTK/Thunk/OneMainPagesSectionThunk";
import AlertDialog from "../../../components/AlertBox/AlertBox";
import EmptyBox from "../../../components/EmptyBox/EmtyBox";
import { Loader } from "../../../components/loader";
import TextEditorWithForm from "../../../components/textEditor/TextEditorWithForm";
import HomeSectionsTitle from "../../../components/HomeSectionsTitle/HomeSectionsTitle";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#C2D2EA",
		color: "#000",
		fontSize: 18,
		fontWeight: 500,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
		padding: "10px",
	},
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme?.palette?.action?.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const schema = Yup.object().shape({
	text: Yup.string().required("قم بإضافة المحتوى النصي"),
	ingredients: Yup.array()
		// test select options and radio buttons
		.of(
			Yup.object().shape({
				// title: Yup.string().required("قم بإضافة عنوان القسم"),
				// sub_title: Yup.string().required("قم بإضافة عنوان فرعي "),
				// link: Yup.mixed().optional("قم بإضافة ربط القسم"),
				image: Yup.mixed().required("قم بإضافة صورة القسم"),
				previewImgOrVideo: Yup.mixed().optional("قم بإضافة اسم القسم"),
			})
		),
});
const HomeSectionImages = ({ showModalAddSection }) => {
	let { name } = useParams();
	let dispatch = useDispatch();
	let {
		section_type,
		SectionData,
		lastPage,
		reload,
		currentPage,
		currentSectionData,
	} = useSelector((state) => state.HomePageReducer);
	// =======react-form====================
	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		resetField,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			text: "",
			ingredients: [
				{
					// title: "",
					sub_title: "",
					image: null,
					previewImgOrVideo: null,
					link: "",
				},
			],
		},
	});

	// console.log("schema", schema);

	const {
		fields: ingredientsFields,
		append: appendIngredientField,
		remove: removeIngredientField,
	} = useFieldArray({
		control,
		name: "ingredients",
	});

	const fileInputRef = React.createRef();
	const handleDeleteImages = (blockIndex) => {
		const updatedBlocks = [...watch("ingredients")];
		updatedBlocks[blockIndex].image = null;
		updatedBlocks[blockIndex].previewImgOrVideo = null;
		setValue("ingredients", updatedBlocks);
		fileInputRef.current.value = null;
	};
	// handle openPreview video modal
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
	const [videoSrc, setVideoSrc] = useState(false);
	const handleOpenPreviewVideoModal = (previewImgOrVideo) => {
		setOpenPreviewModal(true);
		setVideoSrc(previewImgOrVideo);
	};
	// Function to handle file input change
	const handleSetImageInput = (blockIndex, event) => {
		const file = event?.target?.files[0];
		const imgConvert = URL.createObjectURL(file);

		const updatedBlocks = [...watch("ingredients")];
		updatedBlocks[blockIndex].image = file;

		updatedBlocks[blockIndex].previewImgOrVideo = imgConvert;

		setValue("ingredients", updatedBlocks);
	};
	// Function to open the file input dialog
	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	// set err text default
	useEffect(() => {
		// errors?.page_icon;
		if (watch("text") !== "") {
			setError("text");
		}
	}, [setError, watch("text")]);
	// ==================================
	const [deleteId, setDeleteId] = React.useState(0);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [pageTarget, setPageTarget] = useState(1);
	const [rowsCount, setRowsCount] = useState(10);
	const [targetEdit, setTargetEdit] = useState({ type: false, id: "" });

	// ====get table data====
	useEffect(() => {
		dispatch(
			AllMainSectionDataThunk({ page: pageTarget, type: "photo_albums" })
		);
	}, [pageTarget]);
	// ====handle delete====
	const handleDeleteRow = (id) => {
		dispatch(
			DeleteCurrentRowInMainSectionThunk({
				id: id,
				type: "photo_albums",
			})
		)
			.unwrap()
			.then((data) => {
				dispatch(
					AllMainSectionDataThunk({
						page: pageTarget,
						type: "photo_albums",
					})
				);
			})
			.catch((error) => {});
	};
	// get current data target updated
	useEffect(() => {
		if (targetEdit?.type && targetEdit?.id) {
			dispatch(
				OneMainPagesSectionThunk({
					id: targetEdit?.id,
					type: "photo_albums",
				})
			);
		}
	}, [targetEdit]);
	const currentSectionDataRef = React.useRef(true);
	useEffect(() => {
		if (currentSectionData !== null && currentSectionDataRef.current) {
			// console.log(currentSectionData)
			// set ingredients arr data
			removeIngredientField(0);
			appendIngredientField({
				link: currentSectionData?.link || "",
				image: currentSectionData?.image,
				previewImgOrVideo: currentSectionData?.image,
			});
			setValue("text", currentSectionData?.text);

			currentSectionDataRef.current = false;
		}
	}, [currentSectionData]);
	// ==================
	let setFunctionWithEdit = () => {
		setValue("text", "");
		setValue("title", "");
		setTargetEdit({ type: false, id: "" });
		window.scroll(0, 0);
		removeIngredientField(0);
		appendIngredientField({
			// title: "",
			sub_title: "",
			image: null,
			previewImgOrVideo: null,
			link: "",
		});
		dispatch(setCurrentSectionDataStateRow());
	};
	const onSubmit = async (data) => {
		dispatch(
			AddMainSectionInHomeThunk({
				...data,
				//  id: params?.sectionId,
				type: "photo_albums",
				id: targetEdit?.id ? targetEdit?.id : null,
			})
		)
			.unwrap()
			.then((data) => {
				setFunctionWithEdit();
				dispatch(
					AllMainSectionDataThunk({
						page: pageTarget,
						type: "photo_albums",
					})
				);
				//  setShowModalAddSection({ show: false, type: "" });
			})
			.catch((error) => {
				if (error?.data) {
					// if (error?.data["slider.title"]) {
					//   // console.log(error?.data["slider.link"]);
					//   setError(`ingredients.${0}.title`, {
					//     message: error?.data["slider.title"],
					//   });
					// }
					watch(`ingredients`)?.forEach((el, index) => {
						if (error?.data?.link) {
							// console.log(error?.data["slider.link"]);
							setError(`ingredients.${index}.link`, {
								message: error?.data?.link,
							});
						}
						if (error?.data?.image) {
							// console.log(error?.data["slider.link"]);
							setError(`ingredients.${index}.image`, {
								message: error?.data?.image,
							});
						}
						if (error?.data?.title) {
							setError(`ingredients.${index}.title`, {
								message: error?.data?.title,
							});
						}
						if (error?.data?.sub_title) {
							setError(`ingredients.${index}.sub_title`, {
								message: error?.data?.sub_title,
							});
						}
					});
				}

				// console.log(error);
				// handle error here
			});
	};
	const programAdvantageBlock = () => {
		return (
			ingredientsFields?.length > 0 && (
				<>
					{ingredientsFields.map((block, blockIndex) => {
						return (
							<>
								{/* Create program items  */}
								<>
									{/* Upload Videos or images  */}
									{/* <section className="py-[15px] px-[0px]">
                    <SectionLabel label={"عنوان"} icon={<TextIcon />} />
                    <div className="w-[94%] min-h-[56px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow flex justify-start items-center ">
                      <input
                        type="text"
                        // placeholder='فريق عمل في خدمتكم'
                        // value={block?.title}
                        // name="title"
                        // onChange={(e) =>{
                        //   handleSetTitleInput(e, blockIndex);
                        //   // setError(`ingredients[${blockIndex}].image`);
                        //   // watch(`ingredients[${blockIndex}].image`);
                        //   console.log(
                        //     "watch",
                        //     watch(`ingredients${[blockIndex].title}`)
                        //   );
                        // }}

                        {...register(`ingredients.${blockIndex}.title`)}
                        className="w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]"
                      />
                    </div>
                    {errors?.ingredients &&
                      errors?.ingredients[blockIndex]?.title && (
                        <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                          <span>
                            {
                              errors?.ingredients[blockIndex]?.title
                                ?.message
                            }
                          </span>
                        </div>
                      )}
                  </section> */}
									<section className='p-[15px] bg-unnamed_color_f6f6f6 '>
										<SectionLabel label={"صورة"} icon={<ImagesIcon />} />

										{/* Upload image or video Component */}
										<div className='w-full  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
											<input
												type='file'
												accept='image/*'
												control={control}
												onChange={(selectedImageOrVideo) => {
													// console.log(
													//   "selectedImageOrVideo",
													//   selectedImageOrVideo
													// );
													// event?.target?.files[0];
													if (selectedImageOrVideo?.target?.files[0]) {
														setError(`ingredients.${blockIndex}.image`);
														handleSetImageInput(
															blockIndex,
															selectedImageOrVideo
														);
													}
												}}
												ref={fileInputRef}
												name='image'
												/* hidden this input and use icon below to upload  */
												style={{ display: "none" }}
											/>

											{/* Button to trigger file input */}
											<button
												type='button'
												onClick={handleButtonClick}
												className='w-full h-full flex justify-center items-center'>
												{showModalAddSection?.showModalAddSection?.isEdit &&
												typeof watch(`ingredients.${0}.image`) == "string" &&
												showModalAddSection?.targetId ? (
													<div className='p-1 w-full h-full flex justify-center items-center'>
														<img
															src={watch(
																`ingredients.${blockIndex}.previewImgOrVideo`
															)}
															alt={watch(
																`ingredients.${blockIndex}.previewImgOrVideo`
															)}
															className='h-full '
														/>
													</div>
												) : block?.image ? (
													block?.previewImgOrVideo !== null &&
													(block?.previewImgOrVideo?.endsWith(".png") ||
														block?.image?.type?.startsWith("image/")) ? (
														<div className='p-1 w-full h-full flex justify-center items-center'>
															<img
																src={block?.previewImgOrVideo}
																alt={block?.previewImgOrVideo}
																className='h-full '
															/>
														</div>
													) : (
														<>
															{/* <div className="p-1 w-full h-full flex justify-center items-center">
                              {block?.previewImgOrVideo}
                            </div> */}
														</>
													)
												) : (
													<UploadImagesIcon
														className='w-6 h-6'
														title='تغيير صورة '
													/>
												)}
											</button>
										</div>
										{errors?.ingredients &&
											errors?.ingredients[blockIndex]?.image && (
												<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
													<span>
														{errors?.ingredients[blockIndex]?.image?.message}
													</span>
												</div>
											)}
									</section>
									{/* <section className="py-[15px] px-[0px]">
                    <SectionLabel label={"عنوان فرعي"} icon={<TextIcon />} />
                    <div className="w-[94%] min-h-[56px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow flex justify-start items-center ">
                      <input
                        type="text"
                        // placeholder='فريق عمل في خدمتكم'
                        // value={block?.title}
                        // name="title"
                        // onChange={(e) =>{
                        //   handleSetTitleInput(e, blockIndex);
                        //   // setError(`ingredients[${blockIndex}].image`);
                        //   // watch(`ingredients[${blockIndex}].image`);
                        //   console.log(
                        //     "watch",
                        //     watch(`ingredients${[blockIndex].title}`)
                        //   );
                        // }}

                        {...register(`ingredients.${blockIndex}.sub_title`)}
                        className="w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]"
                      />
                    </div>
                    {errors?.ingredients &&
                      errors?.ingredients[blockIndex]?.sub_title && (
                        <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                          <span>
                            {
                              errors?.ingredients[blockIndex]?.sub_title
                                ?.message
                            }
                          </span>
                        </div>
                      )}
                  </section> */}
									{/* <section className="py-[15px] px-[0px]">
                    <SectionLabel
                      label={"رابط فيديو"}
                      icon={<LinkIcon className="link-icon" />}
                    />

                    <div className="w-[94%]  min-h-[100px] h-auto bg-[#C2D2EA] rounded-2xl section-box-shadow px-4 flex justify-center items-center">
                      <input
                        type="text"
                        name="link"
                        // value={block?.link}
                        {...register(`ingredients.${blockIndex}.link`)}
                        // onChange={(e) => handleSetLinkInput(e, blockIndex)}
                        placeholder={"https://www.kkk.youtube.com"}
                        className="w-full h-11 bg-white m-auto p-5 !border-[1px] !border-solid !border-unnamed_color_119184  rounded-lg"
                      />
                    </div>
                    {errors?.ingredients &&
                      errors?.ingredients[blockIndex]?.link && (
                        <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                          <span>
                            {errors?.ingredients[blockIndex]?.link?.message}
                          </span>
                        </div>
                      )}
                  </section> */}
								</>
							</>
						);
					})}
				</>
			)
		);
	};

	useEffect(() => {
		return () => {
			dispatch(setSectionDataState());
			dispatch(setCurrentSectionDataStateRow());
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | إعدادات الصفحة الرئيسية</title>
			</Helmet>
			<div className='py-4 px-14'>
				{/*  Pages Bread crumbs */}
				<section>
					<PagesBreadcrumbs
						parentPage={"الرئيسية"}
						currentPage={`${name}`}
						isNested={true}
						route={"/website-home-page"}
					/>
				</section>
				<HomeSectionsTitle SectionName={"photo_albums"} />

				{reload ? (
					<Loader />
				) : (
					<form className='p-[25px]' onSubmit={handleSubmit(onSubmit)}>
						{programAdvantageBlock()}
						<div className='py-[15px] '>
							{" "}
							<TextEditorWithForm
								placeholder={" المحتوى النصي للقسم المحتوى"}
								height={"300px"}
								setValue={setValue}
								watch={watch}
								register={register}
								name='text'
							/>
							<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
								<span>{errors?.text?.message}</span>
							</div>
						</div>
						<div>
							{!targetEdit?.type && (
								<button
									title='اضافه سلايد جديد'
									type='submit'
									onClick={() => {
										window.scroll(0, 0);
									}}
									className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-white border-unnamed_color_055345  bg-unnamed_color_119184   rounded  cursor-pointer'>
									<>
										اضافه
										<PlusIcon className='add-icon' />
									</>
								</button>
							)}
							{targetEdit?.type && (
								<div className=' flex justify-center items-center w-full gap-[20px]'>
									<button
										title='تعديل سلايد'
										type='submit'
										onClick={() => {
											window.scroll(0, 0);
										}}
										className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-white border-unnamed_color_055345  bg-unnamed_color_119184   rounded  cursor-pointer'>
										<>
											تعديل
											<Edit className='add-icon' />
										</>
									</button>
									<div
										title='الغاء التعديل'
										onClick={() => {
											setFunctionWithEdit();
										}}
										className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-white border-unnamed_color_055345  bg-unnamed_color_119184   rounded  cursor-pointer'>
										<>
											الغاء التعديل
											<Edit className='add-icon' />
										</>
									</div>
								</div>
							)}
						</div>
					</form>
				)}

				<div className=' w-full h-[2px] bg-unnamed_color_C6C6C6 mb-[40px] mt-[60px]'></div>

				<>
					{reload ? (
						<Loader />
					) : SectionData.length ? (
						<>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 700 }} aria-label='customized table'>
									<TableHead>
										<TableRow>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												الرقم
											</StyledTableCell>
											{/* <StyledTableCell
                        align="center"
                        className="!bg-primaryBg capitalize"
                      >
                        العنوان
                      </StyledTableCell> */}
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												النص
											</StyledTableCell>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												الصورة
											</StyledTableCell>

											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												أجراءات
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{SectionData?.map((row, index) => (
											<StyledTableRow key={row.id}>
												<StyledTableCell align='center'>
													{index + 1}
												</StyledTableCell>
												{/* <StyledTableCell align="center">
                          <div className="truncate w-[300px] mx-auto">
                            {row.title}
                          </div>
                        </StyledTableCell> */}
												<StyledTableCell align='center'>
													<div
														className='w-full'
														dangerouslySetInnerHTML={{
															__html: row.text,
														}}
													/>
												</StyledTableCell>

												<StyledTableCell align='' className='!text-center'>
													<img
														src={row.image}
														alt=''
														className=' object-cover w-[60px] h-[60px] mx-auto'
													/>
												</StyledTableCell>

												<StyledTableCell align='center'>
													<div className='action flex items-center justify-center gap-2'>
														<IconButton
															aria-label=''
															onClick={() => {
																currentSectionDataRef.current = true;
																window.scroll(0, 0);
																setTargetEdit({ type: true, id: row.id });
															}}>
															<ModeEdit />
														</IconButton>

														<IconButton
															aria-label=''
															onClick={() => {
																setOpenAlert(true);
																setDeleteId(row.id);
															}}>
															<DeleteForever className='text-[red]' />
														</IconButton>
													</div>
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{SectionData?.length !== 0 && (
								<PaginationBox
									pageCount={lastPage}
									rowsCount={rowsCount}
									currentPage={currentPage}
									setPageTarget={setPageTarget}
									setRowsCount={setRowsCount}
								/>
							)}
						</>
					) : (
						<EmptyBox />
					)}
					<AlertDialog
						open={openAlert}
						setOpen={setOpenAlert}
						handleDelete={handleDeleteRow}
						deleteId={deleteId}
						setDeleteId={setDeleteId}
					/>
				</>
			</div>
			<PreviewVideoModal
				{...{ openPreviewModal, setOpenPreviewModal, videoSrc, setVideoSrc }}
			/>
		</>
	);
};

export default HomeSectionImages;
