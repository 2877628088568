import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let DeletePageThunk = createAsyncThunk(
	"pages/DeletePageThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.delete(
				`/api/admin/pages/${arg?.id}`
			);

			return res.data;
		} catch (error) {
			// console.log(error.response.data);
			return rejectWithValue(error.response.data);
		}
	}
);
