import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FaRegClock } from "react-icons/fa";
import { MdDeleteOutline, MdNotifications } from "react-icons/md";
import Badge from "@mui/material/Badge";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import {
	GetAllNotificationsThunk,
	UnreadNotificationsCountThunk,
	MarkAllNotificationsAsReadThunk,
	DeleteAllNotificationsAsReadThunk,
	DeleteNotificationsAsReadByIdThunk,
	MarkOneNotificationsAsReadByIdThunk,
} from "../../../RTK/Thunk/NotificationsThunk";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { messaging, onMessage, getToken } from "../../../firebase"; // Import your firebase configuration
import "./Notifications.css";

// Import your notification sound file
import notificationSound from "../../../assets/notification.mp3";

const Notifications = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [lastNotificationCount, setLastNotificationCount] = useState(0);
	const [audio] = useState(new Audio(notificationSound));

	// Get notifications from Redux store
	const { notifications, UnreadNotificationsCount, reload } = useSelector(
		(state) => state.NotificationsReducer
	);

	useEffect(() => {
		dispatch(GetAllNotificationsThunk());
		dispatch(UnreadNotificationsCountThunk());
	}, [dispatch]);

	useEffect(() => {
		if (UnreadNotificationsCount > lastNotificationCount) {
			audio.play().catch((error) => {
				console.error("Error playing notification sound:", error);
			});
			const newNotifications = notifications.slice(
				0,
				UnreadNotificationsCount - lastNotificationCount
			);
			newNotifications.forEach((not) => {
				toast.info(`${not.title}:  ${not.body}`, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
		}
		setLastNotificationCount(UnreadNotificationsCount);
	}, [UnreadNotificationsCount, lastNotificationCount, audio, notifications]);

	const handleMarkAllNotificationAsRead = async () => {
		if (UnreadNotificationsCount === 0) return;

		try {
			await dispatch(MarkAllNotificationsAsReadThunk()).unwrap();
			dispatch(GetAllNotificationsThunk());
			dispatch(UnreadNotificationsCountThunk());
			setIsDropdownOpen(false); // Close menu
		} catch (err) {
			console.error("Failed to mark all notifications as read", err);
		}
	};

	const handleMarkSingleNotificationAsRead = async (id, notType) => {
		if (UnreadNotificationsCount === 0) return;

		if (notType === "order_created") {
			navigate("/orders");
		}
		try {
			await dispatch(MarkOneNotificationsAsReadByIdThunk({ id })).unwrap();
			dispatch(GetAllNotificationsThunk());
			dispatch(UnreadNotificationsCountThunk());
			setIsDropdownOpen(false); // Close menu
		} catch (err) {
			console.error("Failed to mark notification as read", err);
		}
	};

	const handleDeleteAllItems = async () => {
		try {
			await dispatch(DeleteAllNotificationsAsReadThunk()).unwrap();
			dispatch(GetAllNotificationsThunk());
			dispatch(UnreadNotificationsCountThunk());
			setIsDropdownOpen(false); // Close menu
		} catch (err) {
			console.error("Failed to delete all notifications", err);
		}
	};

	const handleDeleteSingleNotification = async (id) => {
		try {
			await dispatch(DeleteNotificationsAsReadByIdThunk({ id })).unwrap();
			dispatch(GetAllNotificationsThunk());
			dispatch(UnreadNotificationsCountThunk());
		} catch (err) {
			console.error("Failed to delete the notification", err);
		}
	};

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	return (
		<div className='notifications-container'>
			<div className='nav-link' onClick={() => toggleDropdown()}>
				<Badge
					max={99}
					badgeContent={UnreadNotificationsCount}
					sx={{
						"& .MuiBadge-badge": {
							backgroundColor: "#ffc06a",
							border: "1px solid #fff",
							color: "#fff",
						},
					}}>
					<MdNotifications
						title='Notifications'
						style={{ width: "24px", height: "24px", fill: "#658ECA" }}
					/>
				</Badge>
			</div>
			{isDropdownOpen && (
				<div className='dropdown-menu'>
					{notifications?.length > 0 && (
						<div className='dropdown-menu-header'>
							<button onClick={handleMarkAllNotificationAsRead}>
								تعليم الكل كمقروء
							</button>
							<button onClick={handleDeleteAllItems}>حذف الكل</button>
						</div>
					)}
					{reload ? (
						Array.from({ length: 3 }).map((_, index) => (
							<div key={index} className='notification-item'>
								<h4 className='not-title'>
									<Skeleton variant='text' height={20} width={150} />
								</h4>
								<p className='not-body'>
									<Skeleton variant='text' height={20} width={250} />
								</p>
								<p className='not-date'>
									<Skeleton variant='text' height={20} width={100} />
								</p>
							</div>
						))
					) : notifications?.length ? (
						notifications?.map((not) => (
							<div
								key={not.id}
								className={`notification-item relative ${
									!not.seen ? "isNotRead" : ""
								}`}
								onClick={() =>
									handleMarkSingleNotificationAsRead(not.id, not.data.type)
								}>
								<button
									className='w-8 h-8 absolute left-0 top-1'
									onClick={() => {
										handleDeleteSingleNotification(not.id);
									}}>
									<MdDeleteOutline className='text-rose-500' />
								</button>
								<h4 className={`not-title ${!not.seen ? "isNotRead" : ""}`}>
									{not.title}
								</h4>
								<p className={`not-body ${!not.seen ? "isNotRead" : ""}`}>
									{not.body}
								</p>
								<p className='not-date'>
									{moment(not.created_at).fromNow()}
									<FaRegClock
										style={{
											color: "#283078",
											paddingRight: "4px",
											fontSize: "16px",
										}}
									/>
								</p>
							</div>
						))
					) : (
						<p className='h-36 p-5 flex justify-center items-center text-lg'>
							لا توجد اشعارات حتي هذة اللحظة!{" "}
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default Notifications;
