import React, { Fragment, useContext, useState } from "react";
import ReactDom from "react-dom";
import Context from "../../../../context/Context";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// icon
import { CloseIcon } from "../../../../assets/icons";

import { Rating } from "@mui/material";

// css styles
import styles from "./CustomerServiceInfo.module.css";
import {
	ConfirmOrderThunk,
	FinishOrderThunk,
	OrdersThunk,
} from "../../../../RTK/Thunk/OrdersThunk";

const CustomerServiceInfo = ({ order, pageTarget, rowsCount }) => {
	const dispatch = useDispatch();
	// this context to open and close customer service info modal
	const customerServiceModalContext = useContext(Context);
	const { setOpenCustomerServiceModal, customerServiceInfo } =
		customerServiceModalContext;

	// handle service Info Values
	const [driverRating, setDriverRating] = React.useState(
		customerServiceInfo?.ratings?.driver
	);

	const [carRating, setCarRating] = React.useState(
		customerServiceInfo?.ratings?.car
	);
	const [customerServiceInfoValues, setCustomerServiceInfoValues] = useState({
		fullName: customerServiceInfo?.name,
		phoneNumber: customerServiceInfo?.phone,
		email: customerServiceInfo?.email,
		from: customerServiceInfo?.from,
		to: customerServiceInfo?.to,
		age: customerServiceInfo?.age,
		gender: customerServiceInfo?.gender === "male" ? "ذكر " : "أنثي",
		user_type:
			customerServiceInfo?.user_type === "handicapped"
				? "معاق"
				: customerServiceInfo?.user_type === "old"
				? "كبير سن "
				: customerServiceInfo?.user_type === "other"
				? customerServiceInfo?.other_user_type
				: null,

		purpose: customerServiceInfo?.purpose,
		has_companion:
			customerServiceInfo?.has_companion === true
				? " نعم يوجد مرافق للمريض "
				: "لا يوجد مرافق",
		companion_name: customerServiceInfo?.companion_name,
		companion_age: customerServiceInfo?.companion_age,
		companion_nationality: customerServiceInfo?.companion_nationality,
		companion_gender:
			customerServiceInfo?.companion_gender === "male" ? "ذكر " : "أنثي",

		comment: customerServiceInfo?.comment,
		message: order
			? customerServiceInfo?.description
			: customerServiceInfo?.message,
	});

	// handle in change
	const handleOnChangeCustomerServiceInfo = (e) => {
		const { name, value } = e.target;
		setCustomerServiceInfoValues((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	// handle confirm or reject order
	const handleConfirmOrRejectOrder = async (accept) => {
		dispatch(ConfirmOrderThunk({ id: customerServiceInfo.id, accept }))
			.unwrap()
			.then((data) => {
				if (data?.code === 200 && data?.type === "success") {
					setOpenCustomerServiceModal(false);
					dispatch(OrdersThunk({ page: pageTarget, number: rowsCount }));
				}
			})
			.catch((error) => {
				toast.error(error.message, { theme: "light" });
			});
	};

	// handle Finish Order
	const handleFinishOrderOrder = async () => {
		dispatch(FinishOrderThunk({ id: customerServiceInfo.id }))
			.unwrap()
			.then((data) => {
				if (data?.code === 200 && data?.type === "success") {
					setOpenCustomerServiceModal(false);
					dispatch(OrdersThunk({ page: pageTarget, number: rowsCount }));
				}
			})
			.catch((error) => {
				toast.error(error.message, { theme: "light" });
			});
	};

	return (
		<div>
			{/* Backdrop */}
			<div className={`${styles.backdrop} z-20`} />
			<div
				className={` ${styles.z_index_100} absolute top-20 left-0 translate-x-[40%] md:w-[55%] w-full  bg-white rounded-[10px]`}>
				{/* title of modal */}
				<div
					className={`w-full h-11 bg-[#30506Bff]  flex justify-center items-center px-6 ${styles.modal_title}`}>
					<h4 className='text-[16px] text-white font-medium flex-1 text-center'>
						{order ? "تفاصيل الطلب" : "تفاصيل الرسالة"}
					</h4>

					<div
						className='cursor-pointer '
						onClick={() => setOpenCustomerServiceModal(false)}>
						<CloseIcon className={styles.close_video_icon} />
					</div>
				</div>

				{/* content of modal */}
				<div className=' py-5 w-full overflow-auto'>
					{/* full Name */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
							اسم العميل
						</h4>
						<input
							readOnly
							name='fullName'
							type='text'
							value={customerServiceInfoValues?.fullName}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='أحمد عبد العزيز المالكي'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* phoneNumber */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
							رقم الجوال
						</h4>
						<input
							readOnly
							name='phoneNumber'
							type='tel'
							value={customerServiceInfoValues?.phoneNumber}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='96651545461'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{order && (
						<>
							<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
								<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
									نقل من
								</h4>
								<input
									readOnly
									name='from'
									type='text'
									value={customerServiceInfoValues?.from}
									onChange={handleOnChangeCustomerServiceInfo}
									placeholder='نقل من'
									className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
								/>
							</div>

							<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
								<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
									إلي
								</h4>
								<input
									readOnly
									name='to'
									type='text'
									value={customerServiceInfoValues?.to}
									onChange={handleOnChangeCustomerServiceInfo}
									placeholder='نقل إلي'
									className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
								/>
							</div>

							<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
								<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
									جنس المستفيد
								</h4>
								<input
									readOnly
									name='gender'
									type='text'
									value={customerServiceInfoValues?.gender}
									onChange={handleOnChangeCustomerServiceInfo}
									placeholder='النوع'
									className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
								/>
							</div>
							<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
								<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
									عُمْر المستفيد
								</h4>
								<input
									readOnly
									name='age'
									type='text'
									value={customerServiceInfoValues?.age}
									onChange={handleOnChangeCustomerServiceInfo}
									placeholder='عُمْر المستفيد'
									className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
								/>
							</div>

							<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
								<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
									حالة المستفيد
								</h4>
								<input
									readOnly
									name='user_type'
									type='text'
									value={customerServiceInfoValues?.user_type}
									onChange={handleOnChangeCustomerServiceInfo}
									placeholder='حاله المستفيد'
									className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
								/>
							</div>
							<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
								<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
									الغرض من طلب النقل
								</h4>
								<input
									readOnly
									name='purpose'
									type='text'
									value={customerServiceInfoValues?.purpose}
									onChange={handleOnChangeCustomerServiceInfo}
									placeholder='الغرض من طلب النقل'
									className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
								/>
							</div>
							<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
								<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
									هل يوجد مرافق للمستفيد
								</h4>
								<input
									readOnly
									name='has_companion'
									type='text'
									value={customerServiceInfoValues?.has_companion}
									onChange={handleOnChangeCustomerServiceInfo}
									placeholder='هل يوجد مرافق للمستفيد؟'
									className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
								/>
							</div>
							{customerServiceInfo?.has_companion === true && (
								<>
									<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
										<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
											اسم المرافق
										</h4>
										<input
											readOnly
											name='companion_name'
											type='text'
											value={customerServiceInfoValues?.companion_name}
											onChange={handleOnChangeCustomerServiceInfo}
											placeholder=' اسم المرافق'
											className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
										/>
									</div>
									<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
										<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
											عُمْر المرافق
										</h4>
										<input
											readOnly
											name='companion_age'
											type='text'
											value={customerServiceInfoValues?.companion_age}
											onChange={handleOnChangeCustomerServiceInfo}
											placeholder='عُمْر المرافق'
											className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
										/>
									</div>
									<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
										<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
											جنسية المرافق
										</h4>
										<input
											readOnly
											name='companion_nationality'
											type='text'
											value={customerServiceInfoValues?.companion_nationality}
											onChange={handleOnChangeCustomerServiceInfo}
											placeholder=' جنسية المرافق'
											className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
										/>
									</div>
									<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
										<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
											نوع المرافق
										</h4>
										<input
											readOnly
											name='companion_gender'
											type='text'
											value={customerServiceInfoValues?.companion_gender}
											onChange={handleOnChangeCustomerServiceInfo}
											placeholder=' نوع المرافق'
											className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
										/>
									</div>
								</>
							)}

							{customerServiceInfo?.ratings && (
								<>
									{" "}
									<h4 className='text-xl  pt-5 text-unnamed_color_055345 font-medium flex-1 text-center'>
										تقييمات الطلب
									</h4>
									<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
										<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
											ما رأيك في السيارة ؟
										</h4>
										<Rating
											name='car-rating'
											value={carRating}
											onChange={(event, newValue) => {
												setCarRating(newValue);
											}}
											readOnly
										/>
									</div>
									<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
										<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
											ما رأيك في السائق ؟
										</h4>
										<Rating
											name='car-rating'
											value={driverRating}
											onChange={(event, newValue) => {
												setDriverRating(newValue);
											}}
											readOnly
										/>
									</div>
								</>
							)}

							{customerServiceInfo?.comment && (
								<div className='  px-5 mb-5'>
									<h4 className='mb-2 text-[#707070] font-normal text-[16px]'>
										التعليقات الخاصة بالتقييم
									</h4>
									<textarea
										readOnly
										name='message'
										value={customerServiceInfoValues?.comment}
										onChange={handleOnChangeCustomerServiceInfo}
										placeholder='أرغب بالاستفسار بشكل مفصل عن برنامج الرحلة التجارية إلى الصين'
										className='w-full h-56 p-5 bg-[#f2f2f2] resize-none font-normal text-[16px] text-black  '
									/>
								</div>
							)}
						</>
					)}

					{/* message */}
					{customerServiceInfoValues?.message && (
						<div className=' px-5 mb-5'>
							<h4 className='mb-1 text-[#707070] font-normal text-[16px]'>
								{order ? "ملاحظات الطلب" : "محتوي الرسالة"}
							</h4>
							<textarea
								readOnly
								name='message'
								value={customerServiceInfoValues?.message}
								onChange={handleOnChangeCustomerServiceInfo}
								placeholder='أرغب بالاستفسار بشكل مفصل عن برنامج الرحلة التجارية إلى الصين'
								className='w-full h-56 p-5 bg-[#f2f2f2] resize-none font-normal text-[16px] text-black  '
							/>
						</div>
					)}

					{order && (
						<>
							{customerServiceInfo?.status === 1 ? (
								<section className='mb-10 flex justify-center gap-3 items-center'>
									<button
										onClick={() => handleConfirmOrRejectOrder(true)}
										className='w-[154px] h-12 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '>
										<span className='text-white text-lg font-medium '>
											قبول الطلب
										</span>
									</button>

									<button
										onClick={() => handleConfirmOrRejectOrder(false)}
										className='w-[154px] h-12 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-rose-700 rounded '>
										<span className='text-white text-lg font-medium '>
											رفض الطلب
										</span>
									</button>
								</section>
							) : customerServiceInfo?.status === 2 ? (
								<section className='mb-10 '>
									<button
										onClick={() => handleFinishOrderOrder()}
										className='w-[154px] h-12 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-green-400 rounded '>
										<span className='text-white text-lg font-medium '>
											انهاء الرحلة
										</span>
									</button>
								</section>
							) : null}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const CustomerServiceInfoModal = ({ order, pageTarget, rowsCount }) => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<CustomerServiceInfo
					order={order}
					pageTarget={pageTarget}
					rowsCount={rowsCount}
				/>,
				document.getElementById("customer-service-info")
			)}
		</Fragment>
	);
};

export default CustomerServiceInfoModal;
